import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, ModalCloseButton } from '@reassure/ui/core';
import { FormGroup } from '@reassure/ui/forms';
import { FileInput, Form } from '../Forms';
import { Modal } from '../Modal';
import { H3 } from '../Typography';
import { CreateRateVersionFormInput, useCreateRateVersion } from './hooks';
import { Input } from '../Forms/Input';
import { RateGroup } from '../../graphql/types';

interface ModalProps {
    closeForm: () => void;
    rateGroup: RateGroup;
}

export const NewRateGroupRateFormHeader = ({ closeForm, rateGroup }: ModalProps) => (
    <>
        <H3>{`Upload a new rate`}</H3>
        <ModalCloseButton onClick={closeForm} />
    </>
);

export const NewRateGroupRateFormBody = ({ rateGroup, closeForm }: ModalProps) => {
    const form = useForm<CreateRateVersionFormInput>({
        mode: 'onChange',
        defaultValues: {
            rateGroup: rateGroup.id,
        },
    });

    const {
        formState: { isValid },
        handleSubmit,
        register,
        reset,
    } = form;

    const { createRateVersion, submitting, complete } = useCreateRateVersion();

    useEffect(() => {
        if (!complete) return;
        reset();
        closeForm();
    }, [complete, reset, closeForm]);

    return (
        <FormProvider {...form}>
            <Form>
                <Input type="hidden" id="rateGroup" name="rateGroup" register={register} required={true} />

                <FileInput
                    name="files"
                    label={`Choose a new ${rateGroup.name} rate file`}
                    accept={{ 'text/csv': ['.csv'] }}
                    multiple={false}
                    required={true}
                />

                <FormGroup>
                    <Button
                        type="button"
                        primary
                        onClick={handleSubmit(createRateVersion)}
                        disabled={!isValid}
                        submitting={submitting}
                    >
                        Create a new rate version
                    </Button>
                </FormGroup>
            </Form>
        </FormProvider>
    );
};

interface FormProps {
    rateGroup: RateGroup;
}

export const NewRateGroupRateForm = ({ rateGroup, ...otherProps }: FormProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const openForm = useCallback(() => {
        setIsOpen(true);
    }, [setIsOpen]);
    const closeForm = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    return (
        <>
            <Button secondary small onClick={openForm}>
                Upload a new rate
            </Button>

            <Modal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                header={<NewRateGroupRateFormHeader closeForm={closeForm} rateGroup={rateGroup} />}
                body={<NewRateGroupRateFormBody rateGroup={rateGroup} closeForm={closeForm} />}
                {...otherProps}
            />
        </>
    );
};
