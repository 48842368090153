import { DownloadIcon } from '@reassure/ui/icons/standard';
import { BaselineRateVersion } from '../../graphql/types';
import { useDownloadRateVersion } from './hooks';
import { DownloadButton } from './styles';

export const DownloadRateVersion = (rateVersion: Partial<BaselineRateVersion>) => {
    const { downloadRateVersion, loading } = useDownloadRateVersion(rateVersion);
    return (
        <DownloadButton onClick={downloadRateVersion} disabled={loading} aria-label="Download">
            <DownloadIcon />
        </DownloadButton>
    );
};
