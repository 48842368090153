import { Link } from 'react-router-dom';
import { Logout } from '../Logout';
import { StyledHeader } from './styles';

export const Header = () => {
    return (
        <StyledHeader>
            <Link to="/">Control panel</Link>
            <Logout />
        </StyledHeader>
    );
};
