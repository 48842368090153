import { useCallback, useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { useCreateRateVersioDownloadUrlMutation } from '../../graphql/hooks';
import { BaselineRateVersion } from '../../graphql/types';

export const useDownloadRateVersion = ({ rateGroup, version }: Partial<BaselineRateVersion>) => {
    const { createRateVersionDownloadUrl, data, error, loading } = useCreateRateVersioDownloadUrlMutation();

    useEffect(() => {
        if (error) {
            toast.error('Unable to download the rate version');
        }
    }, [error]);

    const downloadRateVersion = useCallback(() => {
        createRateVersionDownloadUrl({
            variables: {
                input: {
                    Key: `${rateGroup}/${version}.csv`,
                },
            },
        });
    }, [createRateVersionDownloadUrl, rateGroup, version]);

    const getFile = useCallback(async () => {
        window.open(data.url);
    }, [data]);

    useEffect(() => {
        if (!data?.url) return;
        getFile();
    }, [data, getFile]);

    return {
        downloadRateVersion,
        loading,
        error,
    };
};
