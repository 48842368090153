import { Auth } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { useCallback, useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useSignOut } from './components/Logout/hooks';

export const useAuthenticated = () => {
    const [authenticated, setAuthenticated] = useState(false);
    const [loading, setLoading] = useState(true);

    const confirmSignIn = useCallback(async () => {
        try {
            const cognitoUser = await Auth.currentAuthenticatedUser();
            setAuthenticated(!!cognitoUser);
        } catch (error) {
            console.error(error);
            setAuthenticated(false);
        }
        setLoading(false);
    }, [setAuthenticated, setLoading]);

    const onAuthEvent = useCallback(
        (data: any) => {
            switch (data?.payload?.event) {
                case 'signIn':
                case 'signOut':
                    confirmSignIn();
                    return;
                default:
                    return;
            }
        },
        [confirmSignIn]
    );

    useEffect(() => {
        const hubListener = Hub.listen('auth', onAuthEvent);

        return () => {
            hubListener();
        };
    }, [onAuthEvent]);

    useEffect(() => {
        confirmSignIn();
    }, [confirmSignIn]);

    return { authenticated, loading };
};

export interface UserAttributes {
    'custom:ADGroups': string;
    email: string;
    family_name: string;
    given_name: string;
    sub: string;
}

export const useUserAttributes = () => {
    const [userAttributes, setUserAttributes] = useState<UserAttributes>();

    const setAttributes = useCallback(async () => {
        try {
            const cognitoUser = await Auth.currentAuthenticatedUser();
            setUserAttributes(cognitoUser.attributes);
        } catch (error) {
            console.error(error);
        }
    }, [setUserAttributes]);

    useEffect(() => {
        setAttributes();
    }, [setAttributes]);

    return { userAttributes };
};

export const useUserPermissionGroups = () => {
    const { userAttributes } = useUserAttributes();
    const [userPermissionGroups, setUserPermissionGroups] = useState<string>();

    useEffect(() => {
        if (!!userAttributes) {
            setUserPermissionGroups(userAttributes['custom:ADGroups']);
        }
    }, [setUserPermissionGroups, userAttributes]);

    return { userPermissionGroups };
};

export const useUserHasPermissionGroup = (group: string) => {
    const { userPermissionGroups } = useUserPermissionGroups();

    return userPermissionGroups?.includes(group);
};

export const useAutoSignOut = () => {
    const { signOut } = useSignOut();

    const onIdle = useCallback(() => {
        signOut();
    }, [signOut]);

    useIdleTimer({
        onIdle,
        timeout: 1000 * 60 * 10,
    });
};
