/**
 * Styled-components theme for Reassure-UI button components
 */

import { 
    lighten, rgba,
} from 'polished';
import pallette from './pallette';
const {
    transparent,
    white,
    lightGreen,
    lightBlue,
} = pallette;


const themeConfig = {
    colors: {
        button: {
            primary: {
                text: white,
                background: lightGreen,
                border: lightGreen,
                hover: {
                    text: white,
                    background: lighten(0.05, lightGreen),
                    border: lighten(0.05, lightGreen),
                },
                disabled: {
                    text: rgba(white, 0.5),
                    background: lighten(0.35, lightBlue),
                    border: lighten(0.35, lightBlue),
                }
            },
            secondary: {
                text: lightGreen,
                background: transparent,
                border: lightGreen,
                hover: {
                    text: lighten(0.2, lightGreen),
                    background: transparent,
                    border: lighten(0.2, lightGreen),
                },
                disabled: {
                    text: lighten(0.35, lightBlue),
                    background: transparent,
                    border: lighten(0.35, lightBlue),
                }
            }
        }
    }
}

export default themeConfig;