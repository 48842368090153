import { Container, Row, Col } from '@reassure/ui/core';
import { useParams } from 'react-router-dom';
import { Wrapper } from '../../components/Wrapper';
import { BaselineActionsWidget } from '../../components/Baselines/BaselineActions';
import { BaselineDetails } from '../../components/Baselines/BaselineDetails';
import { Affix } from '../../components/Affix';
import { BaselineTestRunWidget } from '../../components/Baselines/BaselineActions/BaselineTestRun';
import { BaselineRateVersions } from '../../components/Baselines/BaselineRateVersions';
import { Hr } from '../../components/Typography/Hr';
import { LatestScheduledBaselineTestRunWidget } from '../../components/Baselines/BaselineActions/ScheduledBaselineTestRun';
import { ListBaselineEventsWidget } from '../../components/Baselines/BaselineEvents';

type ParamType = {
    id: string;
};

export const Baseline = () => {
    const { id } = useParams<keyof ParamType>() as ParamType;

    return (
        <Container>
            <Wrapper>
                <Row>
                    <Col col={12} md={8} id="baseline-details-wrapper">
                        <BaselineDetails id={id} />
                        <Hr />
                        <BaselineRateVersions id={id} />
                    </Col>
                    <Col>
                        <Affix relativeElementSelector="baseline-details-wrapper">
                            <BaselineActionsWidget id={id} />
                            <BaselineTestRunWidget id={id} />
                            <LatestScheduledBaselineTestRunWidget id={id} />
                            <ListBaselineEventsWidget id={id} />
                        </Affix>
                    </Col>
                </Row>
            </Wrapper>
        </Container>
    );
};
