import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { IdInput } from '../../../graphql/hooks';
import { UpdateBaselineStatusMutation } from '../../../graphql/mutations/baselines';
import { AWAITING_APPROVAL } from '../BaselineStatus';
import { BaselineUpdateStatus } from './BaselineUpdateStatus';

export const BaselineRequestApproval = ({ id }: IdInput) => {
    const [updateBaselineStatus, { data, loading, error }] = useMutation(UpdateBaselineStatusMutation, {
        variables: {
            input: { id, status: AWAITING_APPROVAL },
        },
    });

    useEffect(() => {
        if (!error) return;
        toast.error('Unable to send the approval request for the Baseline');
    }, [error]);

    return (
        <BaselineUpdateStatus
            primary
            handleSubmit={updateBaselineStatus}
            submitting={loading}
            disabled={!!data?.updateBaselineStatus}
        >
            Request approval
        </BaselineUpdateStatus>
    );
};
