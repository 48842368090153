import { DocumentNode, gql } from '@apollo/client';

export const CreateRateVersionMutation: DocumentNode = gql`
    mutation CreateRateVersion($input: CreateRateVersionInput!) {
        createRateVersion(input: $input) {
            id
            createdAt
            createdBy
            description
            rateGroup
            version
        }
    }
`;

export const CreateRateVersionUploadUrlMutation: DocumentNode = gql`
    mutation CreateRateVersionUploadUrl($input: CreateSignedUrlInput!) {
        createRateVersionUploadUrl(input: $input) {
            url
        }
    }
`;

export const CreateRateVersionDownloadUrlMutation: DocumentNode = gql`
    mutation CreateRateVersionDownloadUrl($input: CreateSignedUrlInput!) {
        createRateVersionDownloadUrl(input: $input) {
            url
        }
    }
`;
