import { AWAITING_APPROVAL, DRAFT } from './BaselineStatus';

export const getStatusText = (status: string) =>
    `${status.charAt(0)}${status.slice(1).toLowerCase().replace('_', ' ')}`;

export const getEventText = (status: string) => {
    switch (status) {
        case AWAITING_APPROVAL:
            return 'Approval requested';
        case DRAFT:
            return 'Created';
        default:
            return getStatusText(status);
    }
};
