import styled from 'styled-components';
import { H6 } from '../../Typography';

export const EventWrapper = styled.div`
    display: flex;
    align-items: center;
    position: relative;

    svg {
        width: ${({ theme }) => theme.sizes[5]};
        height: auto;
        margin-right: ${({ theme }) => theme.sizes[5]};
        flex-shrink: 0;
        z-index: 2;
    }

    p {
        margin: 0;
    }

    ${H6} {
        margin: 0;
        padding-bottom: ${({ theme }) => theme.sizes[2]};
    }

    &:not(:first-of-type) {
        margin-top: ${({ theme }) => theme.sizes[4]};
        padding-top: ${({ theme }) => theme.sizes[4]};

        &:after {
            content: '';
            z-index: 1;
            width: ${({ theme }) => theme.sizes[2]};
            height: 100%;
            position: absolute;
            top: -50%;
            left: 7px;
            background-color: ${({ theme }) => theme.colors.paleGrey};
        }
    }
`;

export const EventStatus = styled.div``;
