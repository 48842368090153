import { Container } from '@reassure/ui/core';
import { CreateBaselineForm } from '../../components/Baselines/CreateBaselineForm';
import { H1 } from '../../components/Typography';

export const CreateBaseline = () => {
    return (
        <Container>
            <H1>Create a Draft Baseline</H1>
            <CreateBaselineForm />
        </Container>
    );
};
