import { Container, Row, Col } from '@reassure/ui/core';
import { BaselineDashboardWidget, CreateRateVersionWidget, LiveBaseline } from '../../components/Widgets';
import { Wrapper } from '../../components/Wrapper';

export const Dashboard = () => (
    <Container>
        <Wrapper>
            <Row>
                <Col col={12} lg={9}>
                    <LiveBaseline />
                </Col>
                <Col col={12} lg={3}>
                    <CreateRateVersionWidget />
                </Col>
                <Col col={12} lg={9}>
                    <BaselineDashboardWidget />
                </Col>
            </Row>
        </Wrapper>
    </Container>
);
