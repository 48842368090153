import { DocumentNode, gql } from '@apollo/client';

export const ListRateVersionsByRateGroupQuery: DocumentNode = gql`
    query ListRateVersionsByRateGroup($limit: Int!, $input: ListRateVersionByRateGroupInput!) {
        listRateVersionsByRateGroup(limit: $limit, input: $input) {
            items {
                id
                createdAt
                createdBy
                description
                rateGroup
                version
            }
        }
    }
`;
