/**
 * Styled-components theme for Reassure-UI modal components
 */

import pallette from './pallette';
import navigationTheme from './navigation';
const {
    white,
    darkBlue,
} = pallette;


const themeConfig = {
    colors: {
        modals: {
            text: darkBlue,
            background: white,
            separators: 'rgba(0,0,0,0.2)',
            overlay: 'rgba(0,5,15,0.8)',
            link: navigationTheme.colors.link
        },
    }
}

export default themeConfig;