import { useSignOut } from './hooks';
import { LogoutButton } from './styles';

export const Logout = () => {
    const { signOut, loading } = useSignOut();
    return (
        <LogoutButton small type="button" disabled={loading} onClick={signOut}>
            Log out
        </LogoutButton>
    );
};
