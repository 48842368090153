import { Skeleton } from '@reassure/ui/core';
import { IdInput, useGetLiveBaselineQuery } from '../../../graphql/hooks';
import { BaselineRateVersion } from '../../../graphql/types';
import { ErrorCard } from '../../ErrorCard';
import { RateVersion } from '../../RateVersions';
import { H4, H6, P } from '../../Typography';
import { useCompareBaselineRateVersions } from './hooks';
import { Card } from './styles';

interface CompareLiveBaselineRateVersionsProps {
    id: string;
    compareBaselineId?: string;
    loading: boolean;
}

const RateVersionsLoading = () => {
    return (
        <Card role="listitem" outline contrast rounded>
            <H6>
                <Skeleton width="60%" />
            </H6>
            <P>
                <Skeleton width="80%" />
            </P>
            <Skeleton width="70%" />
        </Card>
    );
};

export const CompareBaselineRateVersions = ({
    id,
    compareBaselineId,
    loading,
}: CompareLiveBaselineRateVersionsProps) => {
    const { data, loading: comparisonLoading, error } = useCompareBaselineRateVersions(id, compareBaselineId);

    if (error) {
        return <ErrorCard>There was a problem loading the rate versions</ErrorCard>;
    }

    return (
        <div role="list">
            {loading || comparisonLoading
                ? [0, 1, 2, 3].map(value => <RateVersionsLoading key={value} />)
                : Array.isArray(data) &&
                  data.map((item: BaselineRateVersion) => (
                      <Card key={item.id} role="listitem" outline contrast rounded>
                          <H6>{item.name}</H6>
                          <RateVersion {...item} />
                      </Card>
                  ))}
        </div>
    );
};

export const BaselineRateVersions = ({ id }: IdInput) => {
    const { data, loading } = useGetLiveBaselineQuery();

    return (
        <>
            <H4>Rate versions</H4>
            <CompareBaselineRateVersions id={id} compareBaselineId={data?.id} loading={loading} />
        </>
    );
};
