import { Skeleton } from '@reassure/ui/core';
import { CheckedCircleColourIcon } from '@reassure/ui/icons/colour';
import { IdInput, useGetBaselineQuery } from '../../../graphql/hooks';
import { BaselineStatusEvent } from '../../../graphql/types';
import { formatDateTime } from '../../../utils/dates';
import { H4, H6, LightText } from '../../Typography';
import { Hr } from '../../Typography/Hr';
import { Widget } from '../../Widgets/styles';
import { getEventText } from '../BaselineStatus/helpers';
import { EventStatus, EventWrapper } from './styles';

export const BaselineEvent = ({ status, updatedAt, updatedBy }: BaselineStatusEvent) => {
    return (
        <EventWrapper>
            <CheckedCircleColourIcon title={getEventText(status)} />
            <EventStatus>
                <H6>{getEventText(status)}</H6>
                <LightText>{`${updatedBy} on ${formatDateTime(updatedAt)}`}</LightText>
            </EventStatus>
        </EventWrapper>
    );
};

const BaselineEventLoading = () => {
    return (
        <Widget>
            <Skeleton />
        </Widget>
    );
};

export const ListBaselineEventsWidget = ({ id }: IdInput) => {
    const { data, error, loading } = useGetBaselineQuery(id);

    if (loading) return <BaselineEventLoading />;

    if (!data || !data?.events?.length || error) {
        return null;
    }

    return (
        <Widget>
            <H4>Events</H4>
            <Hr />
            {data.events.map((event, index) => (
                <BaselineEvent key={index} {...event} />
            ))}
        </Widget>
    );
};
