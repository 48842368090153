import { StyledModal, ModalHeader, ModalBody } from './styles';

export interface ModalProps {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    header: React.ReactNode;
    body: React.ReactNode;
    role: string;
}

export const modalDefaultProps = {
    role: 'dialog',
    isOpen: false,
};

export const Modal = ({ isOpen, setIsOpen, header, body, ...otherProps }: ModalProps & typeof modalDefaultProps) => {
    return (
        <StyledModal isOpen={isOpen} {...otherProps}>
            {header && <ModalHeader>{header}</ModalHeader>}
            <ModalBody>{body}</ModalBody>
        </StyledModal>
    );
};

Modal.defaultProps = modalDefaultProps;
