import { useCallback, useEffect } from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import { useFormContext } from 'react-hook-form';
import { FormGroup, Label } from '@reassure/ui/forms';
import { FileDropzone } from './styles';
import { P } from '../Typography';

export interface FileInputProps extends DropzoneOptions {
    name: string;
    label?: string;
    required?: boolean;
}

export const FileInput = ({ name, label, required, ...dropzoneProps }: FileInputProps) => {
    const { register, unregister, setValue, watch } = useFormContext();

    const files = watch(name);

    const onDrop = useCallback(
        (droppedFiles: any) => {
            setValue(name, droppedFiles, { shouldValidate: true });
        },
        [setValue, name]
    );

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        ...dropzoneProps,
    });

    useEffect(() => {
        register(name, { required });
        return () => {
            unregister(name);
        };
    }, [register, unregister, name, required]);

    return (
        <FormGroup>
            {label && <Label htmlFor={name}>{label}</Label>}
            <FileDropzone {...getRootProps()} id={name} type="file" role="button" aria-label={label || 'File upload'}>
                <input name={name} {...getInputProps()} data-testid="file-input-field" />
                <P>
                    {!!files?.length
                        ? files.map((file: any) => file.name)
                        : 'Drag and drop a file here or click to select the file...'}
                </P>
            </FileDropzone>
        </FormGroup>
    );
};
