import styled from 'styled-components';
import { Badge } from '../../Badges';
import { H3 } from '../../Typography';

export const BaselineHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${Badge} {
        margin-top: 0;
    }
`;

export const BaselineTitle = styled(H3)`
    margin: ${({ theme }) => theme.space[4]} 0;
`;
