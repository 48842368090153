import styled from 'styled-components';
import { Card, breakpointUp } from '@reassure/ui/core';
import { ErrorCard } from '../ErrorCard';
import { Hr } from '../Typography/Hr';
import { H3 } from '../Typography';

export const LoginErrorCard = styled(ErrorCard)`
    padding: ${({ theme }) => theme.space[4]};
    margin: ${({ theme }) => theme.space[5]} 0;
    p {
        margin: 0;
    }
`;

export const LoginContainer = styled(Card)`
    max-width: 500px;
    margin: auto;
    padding-top: ${({ theme }) => theme.space[5]};

    ${breakpointUp.md`
        padding-top:  20vh;
    `}
`;

export const LoginHr = styled(Hr)`
    margin: ${({ theme }) => theme.space[7]} 0;
`;

export const LoginHeading = styled(H3)`
    margin-bottom: ${({ theme }) => theme.space[5]};
`;
