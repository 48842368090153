import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { IdInput } from '../../../graphql/hooks';
import { UpdateBaselineStatusMutation } from '../../../graphql/mutations/baselines';
import { REJECTED } from '../BaselineStatus';
import { RejectBaselineButton } from './styles';

export const BaselineReject = ({ id }: IdInput) => {
    const [updateBaselineStatus, { data, loading, error }] = useMutation(UpdateBaselineStatusMutation, {
        variables: {
            input: { id, status: REJECTED },
        },
    });

    useEffect(() => {
        if (error) {
            toast.error('Unable to reject the Baseline');
        }
    }, [error]);

    return (
        <RejectBaselineButton
            primary
            handleSubmit={updateBaselineStatus}
            submitting={loading}
            disabled={!!data?.updateBaselineStatus}
        >
            Reject
        </RejectBaselineButton>
    );
};
