import { Skeleton } from '@reassure/ui/core';
import { Label, Select } from '@reassure/ui/forms';
import { Baseline } from '../../../graphql/types';
import { formatDateTime } from '../../../utils/dates';
import { Table, TBody, TD, TH, THead, TR } from '../../Table';
import { H3, P } from '../../Typography';
import { Hr } from '../../Typography/Hr';
import { BaselineStatus } from '../BaselineStatus/BaselineStatus';
import { useBaselineStatusOptions, useFilterBaselines } from './hooks';
import { BaselineCreateWrapper, BaselineListHeader, CreateButton, InputGroup, Link } from './styles';

const TableLoading = () => {
    return (
        <Table>
            <THead>
                <TR>
                    <TH>
                        <Skeleton />
                    </TH>
                </TR>
            </THead>
            <TBody>
                {[0, 1, 2, 3].map(index => (
                    <TR key={index}>
                        <TD>
                            <Skeleton />
                        </TD>
                    </TR>
                ))}
            </TBody>
        </Table>
    );
};

export const BaselineTableItem = ({ id, description, createdBy, createdAt, effectiveDate, status }: Baseline) => {
    const baselineLink = `/baselines/${id}`;
    return (
        <TR>
            <TD>
                <Link to={baselineLink}>{description}</Link>
            </TD>
            <TD>{createdBy}</TD>
            <TD>{formatDateTime(createdAt)}</TD>
            <TD>{formatDateTime(effectiveDate)}</TD>
            <TD>
                <BaselineStatus status={status} />
            </TD>
        </TR>
    );
};

interface FilterBaselinesProps {
    handleStatusChange: Function;
}

export const FilterBaselines = ({ handleStatusChange }: FilterBaselinesProps) => {
    const options = useBaselineStatusOptions();
    return (
        <InputGroup>
            <Label htmlFor="baselineStatus">Status:</Label>
            <Select
                id="baselineStatus"
                input={{
                    name: 'status',
                    onChange: handleStatusChange,
                }}
                options={options}
                emptyValueLabel="All"
            />
        </InputGroup>
    );
};

interface BaselineTableProps {
    data: any;
    loading: boolean;
    error: any;
}

export const BaselineTable = ({ data, loading, error }: BaselineTableProps) => {
    if (loading) return <TableLoading />;

    if (error || !data?.length) {
        return (
            <>
                <Hr />
                <P>There are no baselines available to view</P>
            </>
        );
    }

    return (
        <Table>
            <THead>
                <TR>
                    <TH>Name</TH>
                    <TH>Created by</TH>
                    <TH>Created at</TH>
                    <TH>Effective date</TH>
                    <TH>Status</TH>
                </TR>
            </THead>
            <TBody>
                {data &&
                    data.map(({ id, description, createdBy, createdAt, effectiveDate, status }: Baseline) => (
                        <BaselineTableItem
                            id={id}
                            key={id}
                            description={description}
                            createdAt={createdAt}
                            createdBy={createdBy}
                            effectiveDate={effectiveDate}
                            status={status}
                        />
                    ))}
            </TBody>
        </Table>
    );
};

export const ListBaselines = () => {
    const { data, loading, error, handleStatusChange } = useFilterBaselines();

    return (
        <>
            <BaselineListHeader>
                <BaselineCreateWrapper>
                    <H3>Baselines</H3>
                    <Link to="/baselines/create">
                        <CreateButton secondary small>
                            Create
                        </CreateButton>
                    </Link>
                </BaselineCreateWrapper>

                <FilterBaselines handleStatusChange={handleStatusChange} />
            </BaselineListHeader>

            <BaselineTable data={data} loading={loading} error={error} />
        </>
    );
};
