import { Button } from '@reassure/ui/core';

export interface BaselineUpdateStatusProps {
    handleSubmit: Function;
    [key: string]: any;
}

export const BaselineUpdateStatus = ({ handleSubmit, children, ...otherProps }: BaselineUpdateStatusProps) => {
    return (
        <Button small onClick={handleSubmit} {...otherProps}>
            {children}
        </Button>
    );
};
