import config from '../config.json';

export const { authentication, metaData } = config;

export const getMetaDataGraphQLEndpoint = () => `https://${metaData?.domain}/graphql`;

export const getAuthConfig = () => {
    const { oauth } = authentication;

    const oauthConfig = {
        redirectSignIn: window.location.origin,
        redirectSignOut: window.location.origin,
        ...oauth,
    };

    return {
        ...authentication,
        oauth: oauthConfig,
    };
};
