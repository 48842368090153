import dayjs, { Dayjs } from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

export const isValidDate = (date: string) => {
    let dateParts: string[];
    if (date.split('-').length === 3) {
        dateParts = date.split('T')[0].split('-');
    } else if (date.split('/').length === 3) {
        dateParts = date.split('/').reverse();
    } else {
        return false;
    }
    const d: Date = new Date(Number(dateParts[0]), Number(dateParts[1]) - 1, Number(dateParts[2]));
    return (
        d &&
        d.getFullYear() === Number(dateParts[0]) &&
        d.getMonth() + 1 === Number(dateParts[1]) &&
        d.getDate() === Number(dateParts[2])
    );
};

/**
 * Formats a date in the standard ReAssure date format i.e. "5 January 2018"
 * @param date - a JavaScript date object or an ISO 8601 date string
 */
export const formatDate = (date: string | Date, formatString: string = 'D MMMM YYYY') => {
    if (typeof date !== 'string') {
        date = date.toISOString();
    }

    if (!isValidDate(date)) {
        return null;
    }

    let dt: Dayjs;

    if (typeof date === 'string' && date.substring(10, 11) === 'T') {
        // if the date is an ISO string then ignore the time portion of the string when parsing it
        date = date.substring(0, 10);
    }

    if (date.includes('/')) {
        dt = dayjs(date, 'DD/MM/YYYY', true);
    } else {
        dt = dayjs(date, 'YYYY-MM-DD', true);
    }

    return dt.format(formatString);
};

/**
 * Formats a date time in the standard ReAssure date format
 * @param dateTime - a JavaScript date time object or an ISO 8601 date time string
 */
export const formatDateTime = (dateTime: string | Date) => {
    if (typeof dateTime !== 'string') {
        dateTime = dateTime.toISOString();
    }

    return dayjs(dateTime, undefined, true).format('D MMMM YYYY [at] HH:mm');
};

/**
 * Ensures that an ISO date has the correct trailing zeros
 * Some dates returned from RDS may not have a trailing 0
 * @example 2023-04-13T16:22:52.35Z shuld be 2023-04-13T16:22:52.350Z
 */

export const padISODateTime = (dateTime: string) => {
    const diff = 24 - dateTime.length;
    if (!diff) return dateTime;
    let padding = `0`.repeat(diff);
    if (diff > 3) {
        padding = padding.replace('0', '.');
    }
    return dateTime.replace('Z', `${padding}Z`);
};
