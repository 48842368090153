import useInterval from 'react-useinterval';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { BaselineUpdateStatus } from './BaselineUpdateStatus';
import { useApproveBaseline } from '../../../graphql/hooks';
import { useUserCanApproveBaseline } from './hooks';

dayjs.extend(isSameOrAfter);
interface BaselineApproveProps {
    id: string;
    effectiveDate: string;
}

export const BaselineApprove = ({ id, effectiveDate }: BaselineApproveProps) => {
    const { data, userCanApprove } = useUserCanApproveBaseline(id);
    const { approveBaseline, loading, error } = useApproveBaseline(id);

    const effectiveDateIsInvalid = (effectiveDate: string) => dayjs().isSameOrAfter(effectiveDate);

    const [disabled, setDisabled] = useState(!data || !userCanApprove || effectiveDateIsInvalid(effectiveDate));

    const setBaselineApprovalDisabled = useCallback(() => {
        setDisabled(!data || !userCanApprove || effectiveDateIsInvalid(effectiveDate));
    }, [effectiveDate, setDisabled, userCanApprove, data]);

    useInterval(setBaselineApprovalDisabled, disabled ? null : 1000);

    useEffect(() => {
        if (!data) return;

        setBaselineApprovalDisabled();
    }, [data, setBaselineApprovalDisabled]);

    useEffect(() => {
        if (error) {
            toast.error('Unable to approve the Baseline');
        }
    }, [error]);

    return (
        <BaselineUpdateStatus primary handleSubmit={approveBaseline} submitting={loading} disabled={disabled}>
            Approve
        </BaselineUpdateStatus>
    );
};
