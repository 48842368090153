import styled from 'styled-components';
import { Card as RuiCard } from '@reassure/ui/core';
import {
    CardRadioGroup as RuiCardRadioGroup,
    Input as RuiInput,
    Label as RuiLabel,
    Select as RuiSelect,
    FormGroup as RuiFormGroup,
} from '@reassure/ui/forms';
import { P } from '../Typography';

export const Form = styled.form``;

export const StyledSelect = styled(RuiSelect)`
    width: 100%;
`;

export const StyledInput = styled(RuiInput)``;
export const StyledCardRadioGroup = styled(RuiCardRadioGroup)``;

export const FileDropzone = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    min-height: 100px;
    padding: ${({ theme }) => theme.space[6]};
    background-color: ${({ theme }) => theme.colors.blacks[1]};
    border: 1px dashed ${({ theme }) => theme.colors.blacks[4]};
    border-radius: ${({ theme }) => theme.borderRadius.medium};

    ${P} {
        margin-bottom: 0;
    }
`;

export const CardRadio = styled(RuiCard)`
    padding: 0;
    margin-bottom: ${({ theme }) => theme.space[5]};
    color: ${({ theme: { colors } }) => colors.cardRadioGroup.text};
    background-color: ${({ theme: { colors } }) => colors.cardRadioGroup.background};
    border-color: ${({ theme: { colors } }) => colors.cardRadioGroup.border};
    transition: opacity 0.25s ease-in-out;
    border-radius: ${({ theme }) => theme.borderRadius.small};

    &:hover {
        opacity: 0.8;
    }
`;

export const CardRadioFormGroup = styled(RuiFormGroup)`
    position: relative;
    margin: 0;
`;

export const CardRadioInput = styled.input`
    margin-right: ${({ theme }) => theme.space[6]};
`;

export const CardRadioLabel = styled(RuiLabel)`
    width: 100%;
    padding: ${({ theme }) => theme.space[5]} ${({ theme }) => theme.space[6]};
    margin-left: 0;
    cursor: pointer;
    color: inherit;
`;
