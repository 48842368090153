import { breakpointUp, Button } from '@reassure/ui/core';
import { Drawer as RuiDrawer } from '@reassure/ui/drawer';
import { ClockfaceColourIcon as RuiClockfaceColourIcon } from '@reassure/ui/icons/colour';
import styled from 'styled-components';
import { H4 } from '../../Typography';
import { Widget } from '../../Widgets/styles';
import { BaselineUpdateStatus } from './BaselineUpdateStatus';

export const Drawer = styled(RuiDrawer)`
    > div:first-child {
        border-top: 1px solid ${({ theme }) => theme.colors.background1};
        border-bottom: 1px solid ${({ theme }) => theme.colors.background1};

        button {
            right: 15px;
        }
    }

    > div[role='region'] {
        padding-top: ${({ theme }) => theme.space[5]};
    }
`;

export const RejectBaselineButton = styled(BaselineUpdateStatus)`
    margin-top: ${({ theme }) => theme.space[4]};
    background-color: ${({ theme }) => theme.colors.white};
    border-color: ${({ theme }) => theme.colors.danger};
    color: ${({ theme }) => theme.colors.danger};

    &:hover {
        background-color: ${({ theme }) => theme.colors.danger};
        border-color: ${({ theme }) => theme.colors.danger};
    }
`;

export const TestPackHeading = styled.div`
    display: flex;
    justify-content: space-between;

    svg {
        width: ${({ theme }) => theme.space[6]};
        height: ${({ theme }) => theme.space[6]};
    }
`;

export const ClockfaceColourIcon = styled(RuiClockfaceColourIcon)`
    path {
        stroke: ${({ theme }) => theme.colors.midGreen}!important;
    }
`;

export const BaselineTestRunTitle = styled(H4)`
    margin-bottom: 0;
`;

export const CondensedDownloadButton = styled(Button)`
    width: auto;
    height: ${({ theme }) => theme.space[7]};
    min-height: 0;
    line-height: 1;
    padding: 4px;
    svg {
        height: 20px;
        width: 20px;
    }
`;

export const CondensedWidget = styled(Widget)`
    border: none;
    border-top: 4px solid ${({ theme }) => theme.colors.background1};
    margin: 0;
    margin-top: ${({ theme }) => theme.space[5]};

    ${breakpointUp.sm`
        margin-top: 0;
        border-top: 0;
        border-left: 4px solid ${({ theme }: any) => theme.colors.background1};
    `}
`;
