/**
 * Styled-components theme for Reassure-UI component design system
 */
import { theme as RuiTheme } from "@reassure/ui/core"; 
import pallette from './pallette';
import page from './page';
import animations from './animations';
import badges from './badges';
import buttons from './buttons';
import data from './data';
import forms from './forms';
import help from './help';
import icons from './icons';
import layout from './layout';
import modals from './modals';
import navigation from './navigation';
import panels from './panels';
import documents from './documents';

const theme = {
    name: 'light',
    fonts: {
        sansSerif: '"Open Sans", "Helvetica Neue", "Helvetica", "Roboto", "Arial", sans-serif',
    },
    lineHeights: {
        ...RuiTheme.lineHeights,
        xs: 1,
        small: 1.1,
        medium: 1.2,
        large: 1.3,
        xl: 1.4,
        xxl: 1.5,
    },
    breakpoints: ['320px', '375px', '576px', '768px', '992px', '1400px'],
    colors: {

        primaryText: pallette.darkBlue,
        secondaryText: pallette.darkBlue,

        primary1: 'hsl(220, 43%, 97%)',
        primary2: 'hsl(223, 20%, 94%)',
        primary3: '#ffffff',
        
        background1: 'hsl(220, 43%, 97%)',
        background2: 'hsl(223, 20%, 94%)',
        background3: '#ffffff',
        ...pallette,
        ...page.colors,
        ...animations.colors,
        ...badges.colors,
        ...buttons.colors,
        ...data.colors,
        ...forms.colors,
        ...help.colors,
        ...icons.colors,
        ...layout.colors,
        ...modals.colors,
        ...navigation.colors,
        ...panels.colors,
        ...documents.colors,
    },
};

export default theme;
