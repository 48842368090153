import { useCallback, useState } from 'react';
import { useListBaselinesQuery } from '../../../graphql/hooks';
import { Baseline } from '../../../graphql/types';
import {
    APPROVED,
    AWAITING_APPROVAL,
    DEPLOYED,
    DEPLOYING,
    DEPLOYMENT_FAILED,
    DRAFT,
    FAILED,
    INVALIDATED,
    REJECTED,
    TESTED,
    TESTING,
    TESTING_FAILED,
} from '../BaselineStatus';
import { getStatusText } from '../BaselineStatus/helpers';

export const useFilterBaselines = () => {
    const { data, loading, error } = useListBaselinesQuery({ limit: 20 });
    const [status, setStatus] = useState<string>();

    const handleStatusChange = useCallback(
        (event: any) => {
            setStatus(event.target.value);
        },
        [setStatus]
    );

    return {
        data: status ? data.filter((baseline: Baseline) => baseline.status === status) : data,
        loading,
        error,
        handleStatusChange,
    };
};

export const useBaselineStatusOptions = () => {
    return [
        { label: getStatusText(AWAITING_APPROVAL), value: AWAITING_APPROVAL },
        { label: getStatusText(APPROVED), value: APPROVED },
        { label: getStatusText(DEPLOYING), value: DEPLOYING },
        { label: getStatusText(DEPLOYED), value: DEPLOYED },
        { label: getStatusText(DEPLOYMENT_FAILED), value: DEPLOYMENT_FAILED },
        { label: getStatusText(DRAFT), value: DRAFT },
        { label: getStatusText(FAILED), value: FAILED },
        { label: getStatusText(INVALIDATED), value: INVALIDATED },
        { label: getStatusText(REJECTED), value: REJECTED },
        { label: getStatusText(TESTING), value: TESTING },
        { label: getStatusText(TESTED), value: TESTED },
        { label: getStatusText(TESTING_FAILED), value: TESTING_FAILED },
    ];
};
