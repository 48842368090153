import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client';
import { Auth } from '@aws-amplify/auth';
import { createAuthLink, AUTH_TYPE, AuthOptions } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import { getMetaDataGraphQLEndpoint, getAuthConfig } from './utils';

const authentication = getAuthConfig();

Auth.configure(authentication);

const auth: AuthOptions = {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: async () => {
        const session = await Auth.currentSession();
        return session.getIdToken().getJwtToken();
    },
};

const config = {
    auth,
    region: authentication.region,
    url: getMetaDataGraphQLEndpoint(),
};

const httpLink = new HttpLink({ uri: config.url });

const link = ApolloLink.from([createAuthLink(config), createSubscriptionHandshakeLink(config, httpLink)]);

export const cache = new InMemoryCache();

export const client = new ApolloClient({
    link,
    cache,
});
