import styled from 'styled-components';
import { H1 as RuiH1, H2 as RuiH2, H3 as RuiH3, H4 as RuiH4, H5 as RuiH5, Text, breakpointUp } from '@reassure/ui/core';

export const H1 = styled(RuiH1)`
    font-weight: ${({ theme }) => theme.fontWeights.regular};
    font-size: ${({ theme }) => theme.fontSizes[8]};
    line-height: ${({ theme }) => theme.lineHeights.large};
    color: ${({ theme }) => theme.colors.primaryText};

    ${breakpointUp.md`
        font-size: ${({ theme }: any) => theme.fontSizes[10]};
        line-height: ${({ theme }: any) => theme.lineHeights.medium};
    `}
`;

export const H2 = styled(RuiH2)`
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: ${({ theme }) => theme.fontSizes[7]};
    line-height: ${({ theme }) => theme.lineHeights.large};
    color: ${({ theme }) => theme.colors.primaryText};

    ${breakpointUp.md`
        font-size: 28px;
        line-height: ${({ theme }: any) => theme.lineHeights.medium};
    `}
`;

export const H3 = styled(RuiH3)`
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: ${({ theme }) => theme.fontSizes[6]};
    line-height: ${({ theme }) => theme.lineHeights.large};
    color: ${({ theme }) => theme.colors.primaryText};

    ${breakpointUp.md`
        font-size: ${({ theme }: any) => theme.fontSizes[7]};
        line-height: ${({ theme }: any) => theme.lineHeights.large};
    `}
`;

export const H4 = styled(RuiH4)`
    font-weight: ${({ theme }) => theme.fontWeights.medium};
    font-size: ${({ theme }) => theme.fontSizes[6]};
    line-height: ${({ theme }) => theme.lineHeights.large};
    color: ${({ theme }) => theme.colors.primaryText};
`;

export const H5 = styled(RuiH5)`
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: ${({ theme }) => theme.fontSizes[5]};
    line-height: ${({ theme }) => theme.lineHeights.large};
    color: ${({ theme }) => theme.colors.primaryText};
`;

export const H6 = styled(Text)`
    font-weight: ${({ theme }) => theme.fontWeights.bold};
    font-size: ${({ theme }) => theme.fontSizes[4]};
    line-height: ${({ theme }) => theme.lineHeights.xl};
    color: ${({ theme }) => theme.colors.primaryText};
`;

H6.defaultProps = {
    tag: 'h6',
};
