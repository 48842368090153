import styled from 'styled-components';
import { Button } from '@reassure/ui/core';

export const LogoutButton = styled(Button)`
    width: auto;
    min-height: auto;
    padding: 0;
    border: 0;
    text-decoration: underline;
    transition: 0.2s;

    &:hover,
    &:focus,
    &:active {
        box-shadow: none;
        border: none;
        opacity: 0.8;
    }
`;
