const pallette = {
    transparent: 'rgba(0,0,0,0)',
    white: '#ffffff',
    black: '#000000',
    paleGrey: '#e3e5ed',
    lightGrey: '#d5d7d8',
    midLightGrey: '#ced1de', //'#cdced6', // Note: talk to Dan as this colour looks better than #c9c9c9,
    midGrey: '#9b9ea0',
    paleBlue: '#89b6fa',
    lightBlue: '#3c4880',
    midBlue: '#2c3d6d',
    darkBlue: '#202e5b',
    brightBlue: '#b6cefd',
    midGreen: '#007d39',
    lightGreen: '#4DAA50',
    lightPink: '#ffd5da',
    pink: '#aa4da8',
    midRed: '#ff3c55',
    teal: 'hsla(183, 38%, 37%, 1)',
    orange: '#f3c57e',
    purple: '#bba4db',
    charcoal: '#252525',

    danger: '#ff3c55',
    warning: '#a96700',
    success: '#017d05',
    info: '#202e5b',

    listItem1: '#8692d0',
    listItem2: '#F59600',
    listItem3: '#aa4da8',
    listItem4: '#9b9ea0',
    listItem5: '#8c66c3',
    listItem6: '#7abdc0',
    listItem7: '#eba537',
    listItem8: '#c07abe',
    listItem9: '#b4b7b8',
    listItem10: '#a98dd2',
    listItem11: '#a6d3d4',
    listItem12: '#faca7f',
    listItem13: '#d4a6d3',
    listItem14: '#bbbfcd',
    listItem15: '#a98dd2',
};

export default pallette;