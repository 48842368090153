import styled from 'styled-components';

export const StyledHeader = styled.header`
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    padding: ${({ theme }) => theme.space[6]};
    display: flex;
    justify-content: space-between;
    align-items: center;

    a {
        color: ${({ theme }) => theme.colors.primaryText};
        text-decoration: none;
        font-weight: ${({ theme }) => theme.fontWeights.bold};
        font-size: ${({ theme }) => theme.fontSizes[7]};
    }
`;
