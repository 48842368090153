import { Route, Routes } from 'react-router-dom';
import { Baseline } from './Baseline';
import { CreateBaseline } from './Create';

/**
 * Routes from the "/baselines/*" pattern
 */
export const BaselinesRouter = () => (
    <Routes>
        <Route path="/create" element={<CreateBaseline />} />
        <Route path="/:id" element={<Baseline />} />
    </Routes>
);
