import { useForm } from 'react-hook-form';
import { Button } from '@reassure/ui/core';
import { FormGroup, Label } from '@reassure/ui/forms';
import { Form } from '../Forms';
import { Input } from '../Forms/Input';
import { P } from '../Typography';
import { getAWSAccount, isDevelopmentAccount } from '../../utils/authentication';
import config from '../../config.json';
import { useDeveloperSignIn, useFederatedSignIn } from './hooks';
import { LoginFormValues } from '../../graphql/types';
import { LoginContainer, LoginErrorCard, LoginHeading, LoginHr } from './styles';

export const Login = () => {
    const account = getAWSAccount(config);

    return (
        <LoginContainer>
            <FederatedLogin />
            {isDevelopmentAccount(account) && <DeveloperLoginForm />}
        </LoginContainer>
    );
};

const LoginError = () => {
    return (
        <LoginErrorCard>
            <P>There was an error trying to sign in</P>
        </LoginErrorCard>
    );
};

export const DeveloperLoginForm = () => {
    const { signIn, error, loading } = useDeveloperSignIn();
    const form = useForm<LoginFormValues>({ mode: 'onChange' });
    const {
        register,
        handleSubmit,
        formState: { isValid },
    } = form;

    return (
        <>
            <LoginHr />
            <LoginHeading>Developer log in</LoginHeading>
            <Form onSubmit={handleSubmit(signIn)}>
                <FormGroup>
                    <Label htmlFor="username">Username</Label>
                    <Input id="username" name="username" register={register} required={true} />
                </FormGroup>
                <FormGroup>
                    <Label htmlFor="password">Password</Label>
                    <Input id="password" name="password" type="password" register={register} required={true} />
                </FormGroup>

                {error && <LoginError />}

                <Button type="submit" disabled={loading || !isValid} submitting={loading}>
                    Log in
                </Button>
            </Form>
        </>
    );
};

export const FederatedLogin = () => {
    const { signIn, error, loading } = useFederatedSignIn();

    return (
        <>
            <LoginHeading>Control panel log in</LoginHeading>

            {error && <LoginError />}

            <Button primary type="submit" onClick={signIn} disabled={loading} submitting={loading}>
                Log in
            </Button>
        </>
    );
};
