import { UseFormRegister, Validate } from 'react-hook-form';
import { StyledInput } from './styles';

export interface InputProps {
    checked?: boolean;
    id?: string;
    name: string;
    onChange?: (event: any) => void;
    pattern?: RegExp;
    register: UseFormRegister<any>;
    required: boolean;
    setValueAs?: (value: any) => void;
    type?: string;
    validate?: Validate<any, any>;
    value?: string;
}

export const Input = ({
    name,
    register,
    required,
    type,
    pattern,
    onChange,
    setValueAs,
    validate,
    ...otherProps
}: InputProps) => (
    <StyledInput
        name={name}
        input={{ ...register(name, { required, pattern, onChange, setValueAs, validate }) }}
        type={type || 'text'}
        {...otherProps}
    />
);
