import { Skeleton } from '@reassure/ui/core';
import { IdInput, useGetBaselineQuery } from '../../../graphql/hooks';
import { formatDateTime } from '../../../utils/dates';
import { ErrorCard } from '../../ErrorCard';
import { H3, LightText, P } from '../../Typography';
import { Hr } from '../../Typography/Hr';
import { BaselineStatus } from '../BaselineStatus';
import { BaselineHeader, BaselineTitle } from './styles';

const BaselineLoading = () => {
    return (
        <>
            {[0, 1, 2, 3].map(index => {
                const width = `${(index + 1) * 15}%`;
                return (
                    <P key={index}>
                        <Skeleton width={width} />
                    </P>
                );
            })}
        </>
    );
};

export const BaselineDetails = ({ id }: IdInput) => {
    const { data, error, loading } = useGetBaselineQuery(id);

    if (loading) return <BaselineLoading />;

    if (!data || error) {
        return (
            <ErrorCard>
                <H3>{error?.message}</H3>
            </ErrorCard>
        );
    }

    const { description, effectiveDate, createdAt, createdBy, status } = data;

    return (
        <>
            <BaselineHeader>
                <BaselineTitle>{description}</BaselineTitle>
                <BaselineStatus status={status} />
            </BaselineHeader>
            <Hr />
            <P secondary lead>
                Effective {formatDateTime(effectiveDate)}
            </P>
            <LightText>{`Created by ${createdBy} on ${formatDateTime(createdAt)}`}</LightText>
        </>
    );
};
