import styled from 'styled-components';
import {
    Table as RuiTable,
    THead as RuiTHead,
    TBody as RuiTBody,
    TR as RuiTR,
    TH as RuiTH,
    TD as RuiTD,
} from '@reassure/ui/tables';

export const Table = styled(RuiTable)`
    border: none;
    margin: ${({ theme }) => theme.space[5]} -${({ theme }) => theme.space[5]};
    width: calc(100% + ${({ theme }) => theme.space[7]});
`;

export const TBody = styled(RuiTBody)``;

export const TD = styled(RuiTD)`
    :first-child {
        color: ${({ theme }) => theme.colors.primaryText};
        font-weight: ${({ theme }) => theme.fontWeights.regular};
    }

    border-bottom: solid 1px ${({ theme }) => theme.colors.paleGrey};
    padding: 10px ${({ theme }) => theme.space[5]};
`;

export const TR = styled(RuiTR)`
    background: none !important;

    :last-child {
        ${TD} {
            border-bottom: none;
        }
    }
`;

export const TH = styled(RuiTH)``;

export const THead = styled(RuiTHead)`
    background-color: ${({ theme }) => theme.colors.background2};
    padding-left: ${({ theme }) => theme.space[5]};

    ${TH} {
        background: none;
        color: ${({ theme }) => theme.colors.primaryText};
        border-bottom: solid 1px ${({ theme }) => theme.colors.lightGrey};
        padding: ${({ theme }) => theme.space[5]};
    }
`;
