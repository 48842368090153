import { DocumentNode, gql } from '@apollo/client';

export const ListBaselines: DocumentNode = gql`
    query ListBaselines($limit: Int, $nextToken: String) {
        listBaselines(limit: $limit, nextToken: $nextToken) {
            items {
                id
                createdAt
                createdBy
                description
                effectiveDate
                status
            }
        }
    }
`;

export const GetBaseline: DocumentNode = gql`
    query GetBaseline($input: IdInput!) {
        getBaseline(input: $input) {
            id
            createdAt
            createdBy
            description
            effectiveDate
            status
            events {
                status
                updatedAt
                updatedBy
            }
        }
    }
`;

export const GetBaselineRateVersions: DocumentNode = gql`
    query GetBaselineRateVersions($input: IdInput!) {
        getBaselineRateVersions(input: $input) {
            createdAt
            createdBy
            description
            id
            rateGroup
            version
        }
    }
`;

export const GetLiveBaseline: DocumentNode = gql`
    query GetLiveBaseline {
        getLiveBaseline {
            id
            createdAt
            createdBy
            description
            effectiveDate
            status
        }
    }
`;

export const ListBaselineTestRuns: DocumentNode = gql`
    query ListBaselineTestRuns($input: IdInput!) {
        listBaselineTestRuns(input: $input) {
            items {
                id
                baselineId
                startedAt
                startedBy
                completedAt
                testPackId
                totalTestScenarios
                resultReference
                error
            }
        }
    }
`;
