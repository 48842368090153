import { useQuery } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { useBaselineRateVersions } from '../../../graphql/hooks';
import { GetBaselineRateVersions } from '../../../graphql/queries/baselines';
import { BaselineRateVersion } from '../../../graphql/types';

export const useCompareBaselineRateVersions = (id: string, compareBaselineId?: string) => {
    const { data: rateVersions, error } = useBaselineRateVersions(id);

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<BaselineRateVersion[] | null>(null);

    const { data: liveRateVersions } = useQuery(GetBaselineRateVersions, {
        variables: {
            input: { id: compareBaselineId },
        },
    });

    const mergeRateVersions = useCallback(() => {
        if (!liveRateVersions && rateVersions) {
            setData(rateVersions);
        } else if (liveRateVersions && rateVersions) {
            const mergedRateVersions = rateVersions.map((currentRateVersion: BaselineRateVersion) => {
                const comparisonRateVersion = liveRateVersions.getBaselineRateVersions.find(
                    ({ rateGroup }: Partial<BaselineRateVersion>) => rateGroup === currentRateVersion.rateGroup
                );

                return {
                    ...currentRateVersion,
                    comparisonVersion: comparisonRateVersion?.version,
                };
            });
            setData(mergedRateVersions);
        }
    }, [setData, rateVersions, liveRateVersions]);

    useEffect(() => {
        if (!data) return;
        setLoading(false);
    }, [data, setLoading]);

    useEffect(() => {
        if (!rateVersions && !liveRateVersions) return;
        mergeRateVersions();
    }, [mergeRateVersions, rateVersions, liveRateVersions]);

    return { data, loading, error };
};
