import { UseFormRegister } from 'react-hook-form';
import { CardRadio, CardRadioFormGroup, CardRadioInput, CardRadioLabel } from './styles';

export interface CardRadioGroupOptionType {
    id: string;
    label: string;
    value: string;
}

export interface CardRadioGroupProps {
    id?: string;
    name: string;
    radios: CardRadioGroupOptionType[];
    register: UseFormRegister<any>;
    required: boolean;
}

export const CardRadioGroup = ({ id, name, register, radios, required }: CardRadioGroupProps) => {
    const input = { ...register(name, { required }), value: undefined };

    return (
        <div role="radiogroup" aria-labelledby={id}>
            {radios &&
                radios.map((radio, index) => {
                    return (
                        <CardRadio outline rounded key={index}>
                            <CardRadioFormGroup>
                                <CardRadioLabel inline>
                                    <CardRadioInput {...input} type="radio" value={radio.value} />
                                    {radio.label}
                                </CardRadioLabel>
                            </CardRadioFormGroup>
                        </CardRadio>
                    );
                })}
        </div>
    );
};
