import { Button } from '@reassure/ui/core';
import { FormGroup, Label } from '@reassure/ui/forms';
import { FieldValues, useForm } from 'react-hook-form';
import { BaselineTestRun } from '../../../graphql/types';
import { Form } from '../../Forms';
import { Input } from '../../Forms/Input';
import { useDownloadTestScenarioDebugFile } from './hooks';

export const DownloadBaselineTestScenarioDebugFile = (props: BaselineTestRun) => {
    const { downloadTestScenarioDebugFile, loading } = useDownloadTestScenarioDebugFile(props);

    const form = useForm<FieldValues>({
        mode: 'onChange',
    });

    const {
        formState: { isValid, isSubmitting },
        handleSubmit,
        register,
    } = form;

    return (
        <Form onSubmit={handleSubmit(downloadTestScenarioDebugFile)}>
            <FormGroup>
                <Label htmlFor="scenario">Scenario</Label>
                <Input id="scenario" name="scenario" register={register} required={true} />
            </FormGroup>

            <Button small submitting={isSubmitting || loading} disabled={!isValid || isSubmitting} type="submit">
                Download
            </Button>
        </Form>
    );
};
