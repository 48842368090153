import { keyframes } from 'styled-components';

export const fadeScaleLinear = (startScale: number, endScale: number) => {
    return keyframes`
        0% {
            opacity: 0;
            transform: scale(${startScale});
        }

        100% {
            opacity: 100%;
            transform: scale(${endScale});
        }
    `;
};
