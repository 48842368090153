import { DocumentNode, gql } from '@apollo/client';

export const ListRateGroups: DocumentNode = gql`
    query ListRateGroups($limit: Int, $nextToken: String) {
        listRateGroups(limit: $limit, nextToken: $nextToken) {
            items {
                id
                name
                order
            }
        }
    }
`;
