import { useCallback, useState } from 'react';
import { normalizeDateTime } from './helpers';

export const useNormaliseDateTime = () => {
    const [previousValue, setPreviousValue] = useState('');

    const normaliseDateTimeOnEvent = useCallback(
        (event: any) => {
            const value = event?.target?.value;
            const result = normalizeDateTime(value, previousValue);
            event.target.value = result;
            setPreviousValue(result);
        },
        [previousValue, setPreviousValue]
    );

    return normaliseDateTimeOnEvent;
};
