import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);
dayjs.extend(isSameOrBefore);

export const normaliseAlphaNumericWithSpaces = (value: string) => value.replace(/[^\dA-Za-z\s]/g, '');
export const normaliseAlphaNumericWithSpacesOnEvent = (event: any) => {
    const value = event?.target?.value;
    event.target.value = normaliseAlphaNumericWithSpaces(value);
};

export const normalizeDateTime = (value: string, previousValue: string) => {
    if (!value) {
        return value;
    }

    // Remove non-numeric characters and replace consecutive slashes with a single slash in first 10 chars
    const date = value.substring(0, 9);
    const sanitizedDate = date.replace(/[^\d/]/g, '');
    value = value.replace(date, sanitizedDate);

    if (value.length > 10) {
        // Remove non-numeric, allowing spaces or colons from time
        const time = value.substring(10, value.length);
        const sanitizedTime = time.replace(/[^\d\s/:]/g, '');
        value = value.replace(time, sanitizedTime);
    }

    // Remove any remaining non-numeric characters and replace consecutive slashes with a single slash in first 10 chars
    if (value.length <= 10) {
        value = value.replace(/[^\d/]/g, '');
    }

    // replace consecutive slashes unless they are in the middle of the date while editing
    if (!/\d+\/{2,}\d+$/.test(value)) {
        value = value.replace(/\/{2,}$/g, '/');
    }

    // Don't allow more than two slashes in a date
    if (value.split('/').length > 3) {
        return previousValue;
    }

    // Don't allow a forward slash at the start of a date
    if (/^\/$/.test(value)) {
        return '';
    }

    // Prefix days and months with zero
    if (/^\d{1,2}\/\d\/$/.test(value)) {
        const daysAndMonths = value.split('/');

        return `${('0' + daysAndMonths[0]).slice(-2)}/${('0' + daysAndMonths[1]).slice(-2)}/`;
    }

    // Prefix days with zero
    if (/^\d\/$/.test(value)) {
        return `0${value}`;
    }

    // Automatically add a slash after the days are entered
    if (/^\d$/.test(previousValue) && /^\d{2}$/.test(value)) {
        return `${value}/`;
    }

    // Automatically add a slash after the months are entered
    if (/^\d{2}\/\d$/.test(previousValue) && /^\d{2}\/\d{2}$/.test(value)) {
        return `${value}/`;
    }

    // Insert a slash if three or four digits are entered for the days
    if (/^\d{3}$/.test(value)) {
        return `${value.substring(0, 2)}/${value.substring(2)}`;
    }

    // Insert two slashes if four or more digits are entered for the days
    if (/^\d{4,8}$/.test(value)) {
        return `${value.substring(0, 2)}/${value.substring(2, 4)}/${value.substring(4)}`;
    }

    // Insert a slash if three digits are entered for the months
    if (/^\d{2}\/\d{3}$/.test(value)) {
        return `${value.substring(0, 2)}/${value.substring(3, 5)}/${value.substring(5, 8)}`;
    }

    // Don't allow more than two digits to be inserted into the days field
    if (/^\d{3,}\/\d{2}\/\d{0,4}$/.test(value)) {
        return previousValue;
    }

    // Don't allow more than two digits to be inserted into the months field
    if (/^\d{2}\/\d{3,}\/\d{0,4}$/.test(value)) {
        return previousValue;
    }

    // Automatically add a space after the years are entered
    if (/^\d{0,2}\/\d{0,2}\/\d{3}$/.test(previousValue) && /^\d{0,2}\/\d{0,2}\/\d{4}$/.test(value)) {
        return `${value} `;
    }

    // Don't allow more than four digits to be inserted into the years field
    if (/^\d{0,2}\/\d{0,2}\/\d{5,}$/.test(value)) {
        return previousValue;
    }

    // Automatically add a space if there is not one at the right position
    if (value.length > 10 && value.substring(10, 11) !== ' ') {
        return `${value.substring(0, 10)} ${value.substring(10, value.length)}`;
    }

    // Don't allow more than one space after the date
    if (/^\d{0,2}\/\d{0,2}\/\d{0,4}\s{2,}$/.test(value)) {
        return previousValue;
    }

    // Automatically add a colon when more than 3 hour digits are added
    if (/^\d{2}\/\d{2}\/\d{4}\s\d{2}$/.test(previousValue) && /^\d{2}\/\d{2}\/\d{4}\s\d{3}$/.test(value)) {
        return `${value.substring(0, value.length - 1)}:${value.substring(value.length - 1, value.length)}`;
    }

    // Automatically add a colon if there is not one at the right position
    if (value.length > 13 && value.substring(13, 14) !== ':') {
        return `${value.substring(0, 13)}:${value.substring(13, value.length)}`;
    }

    // Don't allow more than two digits to be inserted into the hours field
    if (/^\d{0,2}\/\d{0,2}\/\d{0,4}\s\d{3,}$/.test(value)) {
        return previousValue;
    }

    // Don't allow more than two digits to be inserted into the minutes field
    if (/^\d{0,2}\/\d{0,2}\/\d{0,4}\s\d{0,2}:\d{3,}$/.test(value)) {
        return previousValue;
    }

    return value;
};

export const validateDateTime = (date: string) => {
    const dt = dayjs(date, 'DD/MM/YYYY HH:mm');
    const isInFuture = !dt.isSameOrBefore(dayjs());
    return dt.isValid() && isInFuture;
};
