import { DocumentNode, gql } from '@apollo/client';

export const ApproveBaselineMutation: DocumentNode = gql`
    mutation ApproveBaseline($input: IdInput!) {
        approveBaseline(input: $input) {
            id
            createdAt
            createdBy
            description
            effectiveDate
            status
            events {
                status
                updatedAt
                updatedBy
            }
        }
    }
`;

export const CreateBaselineMutation: DocumentNode = gql`
    mutation CreateBaseline($input: CreateBaselineInput!) {
        createBaseline(input: $input) {
            id
            createdAt
            createdBy
            description
            effectiveDate
            events {
                status
                updatedAt
                updatedBy
            }
        }
    }
`;

export const CreateBaselineTestRunMutation: DocumentNode = gql`
    mutation CreateBaselineTestRun($input: IdInput!) {
        createBaselineTestRun(input: $input)
    }
`;

export const UpdateBaselineStatusMutation: DocumentNode = gql`
    mutation UpdateBaselineStatus($input: UpdateBaselineStatusInput!) {
        updateBaselineStatus(input: $input) {
            id
            createdAt
            createdBy
            description
            effectiveDate
            status
            events {
                status
                updatedAt
                updatedBy
            }
        }
    }
`;

export const CreateBaselineTestResultsDownloadUrlMutation: DocumentNode = gql`
    mutation CreateBaselineTestResultsDownloadUrl($input: CreateSignedUrlInput!) {
        createBaselineTestResultsDownloadUrl(input: $input) {
            url
        }
    }
`;
