import { Auth } from '@aws-amplify/auth';
import { useCallback, useState } from 'react';
import { toast } from 'react-hot-toast';

export const useSignOut = () => {
    const [loading, setLoading] = useState<boolean>(false);

    const signOut = useCallback(async () => {
        try {
            setLoading(true);
            await Auth.signOut({ global: true });
        } catch (err) {
            toast.error('There was an error trying to sign out');
        }
        setLoading(false);
    }, [setLoading]);

    return { signOut, loading };
};
