import { useMutation } from '@apollo/client';
import { Button } from '@reassure/ui/core';
import { useEffect } from 'react';
import { toast } from 'react-hot-toast';
import { IdInput } from '../../../graphql/hooks';
import { CreateBaselineTestRunMutation } from '../../../graphql/mutations/baselines';

export const RunBaselineTestRun = ({ id }: IdInput) => {
    const [createBaselineTestRun, { data, loading, error }] = useMutation(CreateBaselineTestRunMutation, {
        variables: {
            input: { id },
        },
    });

    useEffect(() => {
        if (!data && !error) return;
        if (error) {
            toast.error('Unable to start the The Baseline Test Run');
        }
        if (!!data?.createBaselineTestRun) {
            toast.success(`The Baseline Test Run has been successfully started`);
        }
    }, [data, error]);

    return (
        <Button
            small
            primary
            onClick={createBaselineTestRun}
            submitting={loading}
            disabled={data?.createBaselineTestRun}
        >
            Run test pack
        </Button>
    );
};
