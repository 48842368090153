import { CheckedCircleColourIcon, CrossCircleColourIcon } from '@reassure/ui/icons/colour';
import { BaselineTestRun } from '../../../graphql/types';
import { ClockfaceColourIcon } from './styles';

export const BaselineTestRunProgress = ({ error, startedAt, completedAt }: Partial<BaselineTestRun>) => {
    if (error) {
        return <CrossCircleColourIcon title="Testing failed" />;
    } else if (startedAt && completedAt) {
        return <CheckedCircleColourIcon title="Testing complete" />;
    } else {
        return <ClockfaceColourIcon title="Testing in progress" />;
    }
};
