import axios from 'axios';
import { parse } from 'papaparse';
import { useCallback, useEffect, useReducer } from 'react';
import toast from 'react-hot-toast';
import { CreateRateVersionInput, RateGroup, RateVersion } from '../../graphql/types';
import {
    useCreateRateVersionMutation,
    useCreateRateVersionUploadUrlMutation,
    useRateGroups,
} from '../../graphql/hooks';

export const sortByOrderAscending = ({ order: aOrder }: RateGroup, { order: bOrder }: RateGroup) => aOrder - bOrder;

export const useRateGroupOptions = () => {
    const { data, loading } = useRateGroups();
    return {
        loading,
        options:
            data?.length &&
            data.map(({ id, name }: RateGroup) => ({
                label: name,
                value: id,
            })),
    };
};

export interface CreateRateVersionFormInput extends CreateRateVersionInput {
    files: FileList;
}

export type CreateRateVersionUploadUrlInputState = (RateVersion & CreateRateVersionFormInput) | any;

export interface CreateRateVersionUploadUrlHookResponse {
    uploadRateVersion: (input: CreateRateVersionUploadUrlInputState) => void;
    error: boolean;
    input: CreateRateVersionUploadUrlInputState;
    loading: boolean;
    success?: boolean;
}

const RATE_FILE_UPLOAD_SET_ERROR = 'RATE_FILE_UPLOAD_SET_ERROR';
const RATE_FILE_UPLOAD_SET_INPUT = 'RATE_FILE_UPLOAD_SET_INPUT';
const RATE_FILE_UPLOAD_RESET = 'RATE_FILE_UPLOAD_RESET';
const RATE_FILE_UPLOAD_SET_SIGNED_URL = 'RATE_FILE_UPLOAD_SET_SIGNED_URL';
const RATE_FILE_UPLOAD_SET_SUCCESS = 'RATE_FILE_UPLOAD_SET_SUCCESS';

interface ReducerAction {
    type: string;
    payload?: any;
}

interface RateFileUploadState {
    error: boolean;
    input: CreateRateVersionUploadUrlInputState | undefined;
    signedUrl: boolean;
    success: boolean;
}

const defaultRateVersionUploadState = {
    error: false,
    input: undefined,
    signedUrl: undefined,
    success: false,
};

export const rateFileUploadReducer = (state: RateFileUploadState, { type, payload }: ReducerAction) => {
    switch (type) {
        case RATE_FILE_UPLOAD_RESET:
            return defaultRateVersionUploadState;
        case RATE_FILE_UPLOAD_SET_ERROR:
            return {
                ...state,
                error: payload,
            };
        case RATE_FILE_UPLOAD_SET_INPUT:
            return {
                ...state,
                input: payload,
            };
        case RATE_FILE_UPLOAD_SET_SIGNED_URL:
            return {
                ...state,
                signedUrl: payload,
            };
        case RATE_FILE_UPLOAD_SET_SUCCESS:
            return {
                ...state,
                success: payload,
            };
        default:
            return state;
    }
};

export const useUploadRateVersionFile = () => {
    const {
        createRateVersionUploadUrl,
        data,
        error: createRateVersionUploadUrlError,
        loading,
    } = useCreateRateVersionUploadUrlMutation();
    const [state, dispatch] = useReducer(rateFileUploadReducer, defaultRateVersionUploadState);

    const uploadRateVersion = useCallback(
        (input: RateVersion) => {
            dispatch({
                type: RATE_FILE_UPLOAD_SET_INPUT,
                payload: input,
            });
        },
        [dispatch]
    );

    const resetState = useCallback(() => {
        dispatch({
            type: RATE_FILE_UPLOAD_RESET,
        });
    }, [dispatch]);

    const uploadRateVersionFile = useCallback(async () => {
        try {
            const { files } = state.input!;
            const file = files[0];
            const options = {
                headers: {
                    'Content-Type': 'text/csv',
                },
            };
            await axios.put(state.signedUrl, file, options);
            dispatch({
                type: RATE_FILE_UPLOAD_SET_SUCCESS,
                payload: true,
            });
        } catch (error) {
            dispatch({
                type: RATE_FILE_UPLOAD_SET_ERROR,
                payload: true,
            });
        }
    }, [state, dispatch]);

    useEffect(() => {
        if (state.signedUrl || !state.input || state.error || state.success) return;
        const { rateGroup, version } = state.input;
        createRateVersionUploadUrl({
            variables: {
                input: {
                    Key: `${rateGroup}/${version}.csv`,
                },
            },
        });
    }, [createRateVersionUploadUrl, state]);

    useEffect(() => {
        if (!data?.url) return;
        dispatch({
            type: RATE_FILE_UPLOAD_SET_SIGNED_URL,
            payload: data?.url,
        });
    }, [data?.url, dispatch]);

    useEffect(() => {
        if (!state.signedUrl || !state.input || state.success || state.error) return;
        uploadRateVersionFile();
    }, [state, uploadRateVersionFile]);

    useEffect(() => {
        if (!createRateVersionUploadUrlError) return;
        dispatch({
            type: RATE_FILE_UPLOAD_SET_ERROR,
            payload: true,
        });
    }, [createRateVersionUploadUrlError, dispatch]);

    useEffect(() => {
        if (!state.success && !state.error) return;
        resetState();
    }, [resetState, state]);

    return {
        uploadRateVersion,
        loading,
        ...state,
    } as CreateRateVersionUploadUrlHookResponse;
};

export interface CreateRateVersionHookResponse {
    createRateVersion: (input: CreateRateVersionFormInput) => void;
    error: boolean;
    rateVersion: RateVersion;
    submitting: boolean;
    complete: boolean;
}

interface CreateRateVersionState {
    complete: boolean;
    error: boolean;
    input: CreateRateVersionUploadUrlInputState | undefined;
    rateVersion: RateVersion | undefined;
    submitting: boolean;
}

const defaultCreateRateVersionState = {
    complete: false,
    error: false,
    input: undefined,
    rateVersion: undefined,
    submitting: false,
};

const CREATE_RATE_VERSION_SET_COMPLETE = 'CREATE_RATE_VERSION_SET_COMPLETE';
const CREATE_RATE_VERSION_SET_ERROR = 'CREATE_RATE_VERSION_SET_ERROR';
const CREATE_RATE_VERSION_SET_INPUT = 'CREATE_RATE_VERSION_SET_INPUT';
const CREATE_RATE_VERSION_SET_RATE_VERSION = 'CREATE_RATE_VERSION_SET_RATE_VERSION';
const CREATE_RATE_VERSION_SET_SUBMITTING = 'CREATE_RATE_VERSION_SET_SUBMITTING';
const CREATE_RATE_VERSION_RESET = 'CREATE_RATE_VERSION_RESET';

export const createRateVersionReducer = (state: CreateRateVersionState, { type, payload }: ReducerAction) => {
    switch (type) {
        case CREATE_RATE_VERSION_RESET:
            return defaultCreateRateVersionState;
        case CREATE_RATE_VERSION_SET_COMPLETE:
            return {
                ...state,
                complete: payload,
            };
        case CREATE_RATE_VERSION_SET_ERROR:
            return {
                ...state,
                error: payload,
            };
        case CREATE_RATE_VERSION_SET_INPUT:
            return {
                ...state,
                input: payload,
            };
        case CREATE_RATE_VERSION_SET_RATE_VERSION:
            return {
                ...state,
                rateVersion: payload,
            };
        case CREATE_RATE_VERSION_SET_SUBMITTING:
            return {
                ...state,
                submitting: payload,
            };
        default:
            return state;
    }
};

export const useCreateRateVersion = () => {
    const {
        createRateVersionMeta,
        data: rateVersion,
        loading: createRateVersionMetaLoading,
        error: createRateVersionMetaError,
    } = useCreateRateVersionMutation();
    const { uploadRateVersion, error: uploadRateVersionError, success } = useUploadRateVersionFile();
    const [state, dispatch] = useReducer(createRateVersionReducer, defaultCreateRateVersionState);

    const createRateVersion = useCallback(
        (input: CreateRateVersionFormInput) => {
            const { files } = input;
            parse(files[0], {
                header: true,
                complete: ({ meta: { fields } }) => {
                    if (!fields?.length) {
                        toast.error('The Rate file requires a description');
                        dispatch({
                            type: CREATE_RATE_VERSION_SET_ERROR,
                            payload: true,
                        });
                    } else {
                        dispatch({
                            type: CREATE_RATE_VERSION_SET_INPUT,
                            payload: {
                                ...input,
                                description: fields[0],
                            },
                        });
                    }
                },
            });
        },
        [dispatch]
    );

    const resetState = useCallback(() => {
        dispatch({
            type: CREATE_RATE_VERSION_RESET,
        });
    }, [dispatch]);

    useEffect(() => {
        if (!state.input) return;
        createRateVersionMeta({
            variables: {
                input: {
                    rateGroup: state.input.rateGroup,
                    description: state.input.description,
                },
            },
        });
    }, [state.input, createRateVersionMeta]);

    useEffect(() => {
        if (!rateVersion) return;
        dispatch({
            type: CREATE_RATE_VERSION_SET_RATE_VERSION,
            payload: rateVersion,
        });
    }, [rateVersion, dispatch]);

    useEffect(() => {
        if (!state?.rateVersion || !state?.input || state.complete || state.error || success) return;
        uploadRateVersion({
            ...state.input,
            ...state?.rateVersion,
        });
    }, [state, success, uploadRateVersion]);

    useEffect(() => {
        if (!createRateVersionMetaLoading) return;
        dispatch({
            type: CREATE_RATE_VERSION_SET_SUBMITTING,
            payload: true,
        });
    }, [createRateVersionMetaLoading, dispatch]);

    useEffect(() => {
        if (!createRateVersionMetaError && !uploadRateVersionError) return;
        dispatch({
            type: CREATE_RATE_VERSION_SET_ERROR,
            payload: true,
        });
    }, [createRateVersionMetaError, uploadRateVersionError, dispatch]);

    useEffect(() => {
        if (!success) return;
        dispatch({
            type: CREATE_RATE_VERSION_SET_COMPLETE,
            payload: true,
        });
    }, [success, dispatch]);

    useEffect(() => {
        if (!state.complete && !state.error) return;
        if (state.error) {
            toast.error('There was an error creating the new Rate Version');
        } else {
            toast.success(
                `New Rate Version ${state.rateVersion.version} has been created with the description "${state.rateVersion.description}"`
            );
        }
        resetState();
    }, [state, resetState]);

    return {
        createRateVersion,
        ...state,
    } as CreateRateVersionHookResponse;
};
