import { Skeleton } from '@reassure/ui/core';
import { IdInput, useLatestScheduledBaselineTestRun } from '../../../graphql/hooks';
import { H6, LightText } from '../../Typography';
import { formatDateTime } from '../../../utils/dates';
import { BaselineTestRunTitle, CondensedWidget, Drawer, TestPackHeading } from './styles';
import { BaselineTestRunProgress } from './BaselineTestRunProgress';
import { CondensedDownloadBaselineTestResults, DownloadBaselineTestResults } from './DownloadBaselineTestResults';
import { Hr } from '../../Typography/Hr';
import { Widget } from '../../Widgets/styles';
import { BaselineTestRun } from '../../../graphql/types';
import { DownloadBaselineTestScenarioDebugFile } from './DownloadBaselineTestScenarioDebugFile';

export const ScheduledBaselineTestRun = (props: BaselineTestRun) => {
    const { startedAt, completedAt, resultReference, error } = props;
    return (
        <>
            <TestPackHeading>
                <BaselineTestRunTitle>Scheduled test run</BaselineTestRunTitle>
                <BaselineTestRunProgress error={error} startedAt={startedAt} completedAt={completedAt} />
            </TestPackHeading>
            <Hr />

            <LightText>{`Started on: ${formatDateTime(startedAt)}`}</LightText>
            {completedAt && <LightText>{`Completed on: ${formatDateTime(completedAt)}`}</LightText>}

            {resultReference && (
                <>
                    <Hr />
                    <DownloadBaselineTestResults resultReference={resultReference} />
                    <Drawer title="Debug">
                        <DownloadBaselineTestScenarioDebugFile {...props} />
                    </Drawer>
                </>
            )}
        </>
    );
};

export const LatestScheduledBaselineTestRunWidget = ({ id }: IdInput) => {
    const { testRun, loading, error } = useLatestScheduledBaselineTestRun(id);

    if (loading) return <Skeleton />;

    if (!testRun || error) return null;

    return (
        <Widget>
            <ScheduledBaselineTestRun {...testRun} />
        </Widget>
    );
};

const CondensedScheduledBaselineTestRun = ({ startedAt, completedAt, resultReference }: BaselineTestRun) => {
    return (
        <>
            <TestPackHeading>
                <H6>Scheduled test run</H6>
                {resultReference && <CondensedDownloadBaselineTestResults resultReference={resultReference} />}
            </TestPackHeading>

            <LightText>{`Started on: ${formatDateTime(startedAt)}`}</LightText>
            <LightText>{completedAt && `Completed on: ${formatDateTime(completedAt)}`}</LightText>
        </>
    );
};

export const LiveScheduledBaselineTestRunWidget = ({ id }: IdInput) => {
    const { testRun, loading, error } = useLatestScheduledBaselineTestRun(id);

    if (loading) return <Skeleton />;

    if (!testRun || error) return null;
    return (
        <CondensedWidget>
            <CondensedScheduledBaselineTestRun {...testRun} />
        </CondensedWidget>
    );
};
