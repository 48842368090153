import { Toaster } from 'react-hot-toast';
import { ApolloProvider } from '@apollo/client';
import { AppProvider } from '@reassure/ui/core';
import { client } from './graphql/client';
import theme from './theme';
import { Pages } from './pages';
import { BrowserRouter } from 'react-router-dom';
import { Subscriptions } from './components/Subscription';
import { Login } from './components/Login';
import { useAuthenticated, useAutoSignOut } from './hooks';
import { AppLoading } from './styles';

const headConfig = {
    title: 'SQA Control Panel',
    resetCss: `
        *, *::before, *::after {
            box-sizing: border-box;
        }

        html {
            font-family: ${theme.fonts.sansSerif};
            line-height: 1.15;
            -webkit-text-size-adjust: 100%;
            -webkit-tap-highlight-color: rgba(0,0,0,0);
        }

        body {
            color: ${theme.colors.primaryText};
            margin: 0;
            height: inherit;
            color: ${theme.colors.charcoal};
            font-family: ${theme.fonts.sansSerif};
        }
        
        [tabindex="-1"]:focus {
            outline: 0 !important;
        }

        button, input, optgroup, select, textarea {
            font-family: inherit;
            font-size: inherit;
        }
        
    `,
};

const SecureRouter = () => {
    const { authenticated, loading } = useAuthenticated();

    if (loading) return <AppLoading />;
    if (!authenticated) return <Login />;

    return (
        <>
            <Subscriptions />
            <BrowserRouter>
                <Pages />
            </BrowserRouter>
        </>
    );
};

const App = () => {
    useAutoSignOut();

    return (
        <AppProvider theme={theme} head={headConfig}>
            <ApolloProvider client={client}>
                <Toaster
                    toastOptions={{
                        duration: 7000,
                        position: 'top-right',
                        error: {
                            iconTheme: {
                                primary: theme.colors.midRed,
                                secondary: theme.colors.white,
                            },
                        },
                        success: {
                            iconTheme: {
                                primary: theme.colors.lightGreen,
                                secondary: theme.colors.white,
                            },
                        },
                    }}
                />
                <SecureRouter />
            </ApolloProvider>
        </AppProvider>
    );
};

export default App;
