import { Skeleton } from '@reassure/ui/core';
import { IdInput, usePreApprovalBaselineTestRun } from '../../../graphql/hooks';
import { BaselineTestRun as BaselineTestRunType } from '../../../graphql/types';
import { formatDateTime } from '../../../utils/dates';
import { LightText } from '../../Typography';
import { Hr } from '../../Typography/Hr';
import { Widget } from '../../Widgets/styles';
import { BaselineTestRunProgress } from './BaselineTestRunProgress';
import { DownloadBaselineTestResults } from './DownloadBaselineTestResults';
import { DownloadBaselineTestScenarioDebugFile } from './DownloadBaselineTestScenarioDebugFile';
import { BaselineTestRunTitle, Drawer, TestPackHeading } from './styles';

export const BaselineTestRun = (props: BaselineTestRunType) => {
    const { startedAt, startedBy, completedAt, resultReference, error } = props;
    return (
        <>
            <TestPackHeading>
                <BaselineTestRunTitle>Pre-approval test run</BaselineTestRunTitle>
                <BaselineTestRunProgress error={error} startedAt={startedAt} completedAt={completedAt} />
            </TestPackHeading>
            <Hr />

            <LightText>{`Started by: ${startedBy}`}</LightText>
            <LightText>{`Started on: ${formatDateTime(startedAt)}`}</LightText>
            {completedAt && <LightText>{`Completed on: ${formatDateTime(completedAt)}`}</LightText>}

            {resultReference && (
                <>
                    <Hr />
                    <DownloadBaselineTestResults resultReference={resultReference} />
                    <Drawer title="Debug">
                        <DownloadBaselineTestScenarioDebugFile {...props} />
                    </Drawer>
                </>
            )}
        </>
    );
};

export const BaselineTestRunWidget = ({ id }: IdInput) => {
    const { testRun, loading, error } = usePreApprovalBaselineTestRun(id);

    if (loading) return <Skeleton />;

    if (!testRun || error) return null;

    return (
        <Widget>
            <BaselineTestRun {...testRun} />
        </Widget>
    );
};
