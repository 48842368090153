import { Button, Col, ModalCloseButton, Row, Skeleton } from '@reassure/ui/core';
import { FormGroup, Label } from '@reassure/ui/forms';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FileInput, Form, Select } from '../Forms';
import { Modal } from '../Modal';
import { H3 } from '../Typography';
import { CreateRateVersionFormInput, useCreateRateVersion, useRateGroupOptions } from './hooks';

interface ModalHeaderProps {
    closeForm: () => void;
}

export const NewRateFormHeader = ({ closeForm }: ModalHeaderProps) => (
    <>
        <H3>Upload a new rate</H3>
        <ModalCloseButton onClick={closeForm} />
    </>
);

export const NewRateFormBody: React.FC = () => {
    const form = useForm<CreateRateVersionFormInput>({ mode: 'onChange' });

    const {
        formState: { isValid },
        handleSubmit,
        register,
        reset,
    } = form;

    const { loading, options } = useRateGroupOptions();
    const { createRateVersion, submitting, complete } = useCreateRateVersion();

    useEffect(() => {
        if (!complete) return;
        reset();
    }, [complete, reset]);

    return (
        <FormProvider {...form}>
            <Form onSubmit={handleSubmit(createRateVersion)}>
                <FormGroup>
                    <Label htmlFor="rateGroup">{loading ? <Skeleton width="70%" /> : 'Select the rate group'}</Label>
                    {!loading && options ? (
                        <Select id="rateGroup" name="rateGroup" options={options} register={register} required={true} />
                    ) : (
                        <Skeleton />
                    )}
                </FormGroup>

                <FileInput
                    name="files"
                    label="Choose the new rate file"
                    accept={{ 'text/csv': ['.csv'] }}
                    multiple={false}
                    required={true}
                />

                <FormGroup>
                    <Button type="submit" primary disabled={loading || !isValid} submitting={submitting}>
                        Create a new rate version
                    </Button>
                </FormGroup>
            </Form>
        </FormProvider>
    );
};

export const NewRateForm: React.FC = ({ ...otherProps }) => {
    const [isOpen, setIsOpen] = useState(false);
    const openForm = useCallback(() => {
        setIsOpen(true);
    }, [setIsOpen]);
    const closeForm = useCallback(() => {
        setIsOpen(false);
    }, [setIsOpen]);

    return (
        <>
            <Row>
                <Col col={12} sm={8} md={6} lg={12}>
                    <Button secondary small onClick={openForm}>
                        Upload a new rate
                    </Button>
                </Col>
            </Row>
            <Modal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                header={<NewRateFormHeader closeForm={closeForm} />}
                body={<NewRateFormBody />}
                {...otherProps}
            />
        </>
    );
};
