import {
    useOnApproveBaseline,
    useOnBaselineStatusUpdate,
    useOnCompleteBaselineTestRun,
    useOnCreateBaseline,
    useOnCreateRateVersion,
    useOnStartBaselineTestRun,
} from '../../graphql/hooks';

export const Subscriptions = () => {
    useOnBaselineStatusUpdate();
    useOnCreateBaseline();
    useOnStartBaselineTestRun();
    useOnCompleteBaselineTestRun();
    useOnApproveBaseline();
    useOnCreateRateVersion();
    return null;
};
