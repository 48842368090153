import { Routes, Route } from 'react-router-dom';
import { Header } from '../components/Header';
import { BaselinesRouter } from './Baselines/Router';
import { Dashboard } from './Dashboard';

export const Pages = () => (
    <>
        <Header />
        <Routes>
            <Route element={<Dashboard />} path="/" />
            <Route element={<BaselinesRouter />} path="/baselines/*" />
        </Routes>
    </>
);
