import styled from 'styled-components';
import { Badge as RuiBadge, breakpointUp } from '@reassure/ui/core';

export const Badge = styled(RuiBadge)`
    margin-top: ${({ theme }) => theme.space[4]};
    border-width: 1px;
    font-size: ${({ theme }) => theme.fontSizes[1]};
    ${breakpointUp.sm`
        margin-top: 0;
    `}
    color:${({ theme, statusColor }) => theme.colors[statusColor]};
    border-color: ${({ theme, statusColor }) => theme.colors[statusColor]};
`;

export const DangerBadge = styled(Badge)`
    color: ${({ theme }) => theme.colors.danger};
    border-color: ${({ theme }) => theme.colors.danger};
`;

export const SuccessBadge = styled(Badge)`
    color: ${({ theme }) => theme.colors.success};
    border-color: ${({ theme }) => theme.colors.success};
`;
