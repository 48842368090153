import { Badge, DangerBadge, SuccessBadge } from '../../Badges';
import { getStatusText } from './helpers';

interface BaselineStatusType {
    [key: string]: string;
}

export const AWAITING_APPROVAL = 'AWAITING_APPROVAL';
export const APPROVED = 'APPROVED';
export const DEPLOYING = 'DEPLOYING';
export const DEPLOYED = 'DEPLOYED';
export const DEPLOYMENT_FAILED = 'DEPLOYMENT_FAILED';
export const DRAFT = 'DRAFT';
export const FAILED = 'FAILED';
export const INVALIDATED = 'INVALIDATED';
export const REJECTED = 'REJECTED';
export const TESTING = 'TESTING';
export const TESTED = 'TESTED';
export const TESTING_FAILED = 'TESTING_FAILED';

export const BaselineStatus = ({ status }: BaselineStatusType) => {
    switch (status) {
        case DEPLOYMENT_FAILED:
        case FAILED:
        case INVALIDATED:
        case REJECTED:
        case TESTING_FAILED:
            return <DangerBadge>{getStatusText(status)}</DangerBadge>;
        case APPROVED:
        case DEPLOYED:
        case TESTED:
            return <SuccessBadge>{getStatusText(status)}</SuccessBadge>;
        default:
            return <Badge>{getStatusText(status)}</Badge>;
    }
};
