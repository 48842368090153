import { FormProvider, useForm } from 'react-hook-form';
import { Navigate } from 'react-router-dom';
import { Row, Col, Button } from '@reassure/ui/core';
import { FormGroup, Label } from '@reassure/ui/forms';
import { useRateGroups } from '../../../graphql/hooks';
import { CreateBaselineFormValues, RateGroup } from '../../../graphql/types';
import { ErrorCard } from '../../ErrorCard';
import { Form } from '../../Forms';
import { H3 } from '../../Typography';
import { RateVersionSelector } from '../RateVersionSelector';
import { Input } from '../../Forms/Input';
import { normaliseAlphaNumericWithSpacesOnEvent, validateDateTime } from '../../Forms/helpers';
import { FormSectionCard } from './styles';
import { useCreateBaseline } from './hooks';
import { Affix } from '../../Affix';
import { useNormaliseDateTime } from '../../Forms/hooks';

export const CreateBaselineForm = () => {
    const { data, error, loading } = useRateGroups();
    const { createBaselineHandler, baseline, submitting } = useCreateBaseline();
    const form = useForm<CreateBaselineFormValues>({
        mode: 'onChange',
    });
    const normaliseDateTimeOnEvent = useNormaliseDateTime();
    const formWrapperId = 'create-baseline-form-wrapper';
    const {
        formState: { isValid },
        handleSubmit,
        register,
    } = form;

    if (error)
        return (
            <ErrorCard>
                <H3>An error has occured</H3>
            </ErrorCard>
        );

    if (baseline?.id) return <Navigate to={`/baselines/${baseline?.id}`} />;

    return (
        <FormProvider {...form}>
            <Form onSubmit={handleSubmit(createBaselineHandler)}>
                <Row>
                    <Col col={12} md={8} id={formWrapperId}>
                        <FormSectionCard>
                            <FormGroup>
                                <Label htmlFor="description">Description</Label>
                                <Input
                                    id="description"
                                    name="description"
                                    register={register}
                                    onChange={normaliseAlphaNumericWithSpacesOnEvent}
                                    required={true}
                                />
                            </FormGroup>
                        </FormSectionCard>
                        {loading
                            ? [0, 1, 2, 3, 4, 5].map((value: number) => (
                                  <RateVersionSelector key={value} loading={true} />
                              ))
                            : data.map((rateGroup: RateGroup) => (
                                  <RateVersionSelector key={rateGroup.id} rateGroup={rateGroup} />
                              ))}
                    </Col>
                    <Col>
                        <Affix relativeElementSelector={formWrapperId}>
                            <FormSectionCard>
                                <FormGroup>
                                    <Label htmlFor="effectiveDate">Effective date</Label>
                                    <Input
                                        id="effectiveDate"
                                        name="effectiveDate"
                                        register={register}
                                        onChange={normaliseDateTimeOnEvent}
                                        required={true}
                                        validate={validateDateTime}
                                    />
                                </FormGroup>
                                <Button
                                    submitting={submitting}
                                    disabled={loading || submitting || !isValid}
                                    type="submit"
                                >
                                    Create Baseline
                                </Button>
                            </FormSectionCard>
                        </Affix>
                    </Col>
                </Row>
            </Form>
        </FormProvider>
    );
};
