import { Skeleton } from '@reassure/ui/core';
import { IdInput, useGetBaselineQuery } from '../../../graphql/hooks';
import { Baseline } from '../../../graphql/types';
import { H4, LightText } from '../../Typography';
import { Widget } from '../../Widgets/styles';
import { RunBaselineTestRun } from './RunBaselineTestRun';
import { BaselineRequestApproval } from './BaselineRequestApproval';
import { BaselineApprove } from './BaselineApprove';
import { AWAITING_APPROVAL, DRAFT, TESTED } from '../BaselineStatus';
import { BaselineReject } from './BaselineReject';
import { Hr } from '../../Typography/Hr';

export const BaselineActions = ({ status, id, effectiveDate }: Baseline) => {
    switch (status) {
        case DRAFT:
            return <RunBaselineTestRun id={id} />;
        case TESTED:
            return <BaselineRequestApproval id={id} />;
        case AWAITING_APPROVAL:
            return (
                <>
                    <BaselineApprove id={id} effectiveDate={effectiveDate} />
                    <BaselineReject id={id} />
                </>
            );
        default:
            return <LightText>There are currently no actions you can perform</LightText>;
    }
};

const BaselineActionsLoading = () => {
    return (
        <Widget>
            <Skeleton />
        </Widget>
    );
};

export const BaselineActionsWidget = ({ id }: IdInput) => {
    const { data, error, loading } = useGetBaselineQuery(id);

    if (loading) return <BaselineActionsLoading />;

    if (!data || error) {
        return null;
    }

    return (
        <Widget>
            <H4>Next steps</H4>
            <Hr />
            <BaselineActions {...data} />
        </Widget>
    );
};
