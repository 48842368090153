import styled from 'styled-components';
import { Text, P as RuiP } from '@reassure/ui/core';

export const Strong = styled(Text)``;

Strong.defaultProps = {
    tag: 'strong',
};

export const Span = styled(Text)``;

Span.defaultProps = {
    tag: 'span',
};

export const Em = styled(Text)``;

Em.defaultProps = {
    tag: 'em',
};

export const P = styled(RuiP)`
    font-size: ${({ theme }) => theme.fontSizes[4]};
    line-height: ${({ theme }) => theme.lineHeights.xl};
    margin-bottom: ${({ theme }) => theme.fontSizes[0]};
`;

export const LightText = styled(P)`
    font-weight: ${({ theme }) => theme.fontWeights.light};
    font-size: ${({ theme }) => theme.fontSizes[3]};
    margin-bottom: ${({ theme }) => theme.space[3]};
`;
