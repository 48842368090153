import { useCallback, useEffect } from 'react';
import { FieldValues } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useCreateBaselineTestResultsDownloadUrlMutation, useGetBaselineQuery } from '../../../graphql/hooks';
import { BaselineTestRun } from '../../../graphql/types';
import { useUserAttributes, useUserHasPermissionGroup } from '../../../hooks';
import { padISODateTime } from '../../../utils/dates';

export const useDownloadTestResults = (resultReference: string) => {
    const { createBaselineTestResultsDownloadUrl, data, error, loading } =
        useCreateBaselineTestResultsDownloadUrlMutation();

    useEffect(() => {
        if (error) {
            toast.error('Unable to download the Baseline test results');
        }
    }, [error]);

    const downloadTestResults = useCallback(() => {
        createBaselineTestResultsDownloadUrl({
            variables: {
                input: {
                    Key: resultReference,
                },
            },
        });
    }, [createBaselineTestResultsDownloadUrl, resultReference]);

    const getTestResultsFile = useCallback(async () => {
        window.open(data.url);
    }, [data]);

    useEffect(() => {
        if (!data?.url) return;
        getTestResultsFile();
    }, [data, getTestResultsFile]);

    return {
        downloadTestResults,
        loading,
        error,
    };
};

export const useDownloadTestScenarioDebugFile = ({ id, baselineId, startedAt }: BaselineTestRun) => {
    const { createBaselineTestResultsDownloadUrl, data, error, loading } =
        useCreateBaselineTestResultsDownloadUrlMutation();

    const downloadTestScenarioDebugFile = useCallback(
        ({ scenario }: FieldValues) => {
            createBaselineTestResultsDownloadUrl({
                variables: {
                    input: {
                        Key: `${padISODateTime(startedAt)}-${id}-${baselineId}-${scenario}-debug.json`,
                    },
                },
            });
        },
        [createBaselineTestResultsDownloadUrl, baselineId, id, startedAt]
    );

    const getTestResultsFile = useCallback(() => {
        if (!data?.url) return;
        window.open(data?.url);
    }, [data?.url]);

    useEffect(() => {
        if (!data?.url) return;
        getTestResultsFile();
    }, [data?.url, getTestResultsFile]);

    useEffect(() => {
        if (!error) return;
        toast.error(`Unable to download the Baseline test scenario debug file`);
    }, [error]);

    return {
        downloadTestScenarioDebugFile,
        error,
        loading,
        url: data?.url,
    };
};

export const useUserCanApproveBaseline = (id: string) => {
    const { userAttributes } = useUserAttributes();
    const { data } = useGetBaselineQuery(id);
    const userHasPermission = useUserHasPermissionGroup('SPCD_Baseline_Approve');
    return {
        data,
        userCanApprove: userAttributes?.email !== data?.createdBy && userHasPermission,
    };
};
