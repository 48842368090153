import { useCallback, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useBaselineRateVersions, useCreateBaselineMutation, useGetLiveBaselineQuery } from '../../../graphql/hooks';
import { CreateBaselineFormValues, CreateBaselineRateVersionFormValues } from '../../../graphql/types';
import { transformCreateBaselineFormValues } from './helpers';

export const useCreateBaseline = () => {
    const { createBaseline, data, loading, error } = useCreateBaselineMutation();

    const createBaselineHandler = useCallback(
        (formValues: CreateBaselineFormValues) => {
            createBaseline({
                variables: {
                    input: transformCreateBaselineFormValues(formValues),
                },
            });
        },
        [createBaseline]
    );

    useEffect(() => {
        if (!data && !error) return;
        if (error) {
            toast.error('There was a problem creating your Baseline');
        }
        if (data?.createdAt) {
            const { description } = data;
            toast.success(`The Baseline "${description}" has been created`);
        }
    }, [data, error]);

    return {
        createBaselineHandler,
        baseline: data,
        error,
        submitting: loading,
    };
};

export const useLiveBaselineRateVersion = (id: string) => {
    const { data } = useGetLiveBaselineQuery();
    const { data: rateVersions } = useBaselineRateVersions(data?.id);

    const rates: CreateBaselineRateVersionFormValues = {};

    for (const rate of rateVersions) {
        rates[rate.rateGroup] = rate.version.toString();
    }

    const rateVersion = rates[id];

    return { rateVersion };
};
