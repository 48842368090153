import { Auth } from '@aws-amplify/auth';
import { useCallback, useState } from 'react';
import { LoginFormValues } from '../../graphql/types';

export const useDeveloperSignIn = () => {
    const [error, setError] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);

    const signIn = useCallback(
        async ({ username, password }: LoginFormValues) => {
            try {
                setLoading(true);
                await Auth.signIn(username, password);
            } catch (err) {
                setError(err);
            }
            setLoading(false);
        },
        [setError, setLoading]
    );

    return { signIn, error, loading };
};

export const useFederatedSignIn = () => {
    const [error, setError] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);

    const signIn = useCallback(async () => {
        try {
            setLoading(true);
            await Auth.federatedSignIn({
                customProvider: 'AzureAD',
            });
        } catch (err) {
            setError(err);
        }
        setLoading(false);
    }, [setError, setLoading]);

    return { signIn, error, loading };
};
