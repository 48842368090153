import { NewRateForm } from '../NewRateForm';
import { H3 } from '../Typography';
import { Widget } from './styles';

export const CreateRateVersionWidget: React.FC = () => (
    <Widget>
        <H3>Rates</H3>
        <NewRateForm />
    </Widget>
);
