/**
 * Styled-components theme for Reassure-UI navigation components
 */

import { lighten } from 'polished';
import pallette from './pallette';
const {
    white,
    lightBlue,
    lightGreen,
} = pallette;


const themeConfig = {
    colors: {
        link: {
            text: lightGreen,
            hover: {
                text: lighten(0.1, lightGreen),
            }
        },
        tabs: {
            text: white,
            background: lightBlue,
        }
    }
}

export default themeConfig;