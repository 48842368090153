import styled from 'styled-components';
import {
    Modal as RuiModal,
    ModalHeader as RuiModalHeader,
    ModalBody as RuiModalBody,
    breakpointUp,
} from '@reassure/ui/core';
import { H2, H3, H4, H5 } from '../Typography';

export const StyledModal = styled(RuiModal)`
    max-height: 90vh;
    border-radius: 0 !important;

    ${breakpointUp.lg`
        max-height: 80vh;
    `}
`;

export const ModalHeader = styled(RuiModalHeader)`
    text-align: center;

    ${H2}, ${H3}, ${H4}, ${H5} {
        margin: 0;
        font-weight: ${({ theme }) => theme.fontWeights.light};
    }
`;

export const ModalBody = styled(RuiModalBody)`
    h4 {
        font-size: ${({ theme }) => theme.fontSizes[4]};
        font-weight: ${({ theme }) => theme.fontWeights.bold};
    }
`;
