interface OAuthOptions {
    domain: string;
    scope: Array<string>;
    responseType: string;
}
interface AuthenticationConfig {
    region: string;
    identityPoolId: string;
    oauth: OAuthOptions;
    userPoolId: string;
    userPoolWebClientId: string;
}

interface MetaDataApiConfig {
    domain: string;
}

interface ConfigType {
    account: string;
    authentication: AuthenticationConfig;
    metaData: MetaDataApiConfig;
}

export const getAWSAccount = (config: ConfigType) => config?.account;

const developmentAccountNumbers = ['770194010275', '319226587326'];
export const isDevelopmentAccount = (account: string) => developmentAccountNumbers.includes(account);
