import { Link } from 'react-router-dom';
import { Button, Col, Row, Skeleton } from '@reassure/ui/core';
import { useGetLiveBaselineQuery } from '../../graphql/hooks';
import { formatDateTime } from '../../utils/dates';
import { ErrorCard } from '../ErrorCard';
import { H3, H5, LightText, P } from '../Typography';
import { Hr } from '../Typography/Hr';
import { Widget } from './styles';
import { LiveScheduledBaselineTestRunWidget } from '../Baselines/BaselineActions/ScheduledBaselineTestRun';

export const LiveBaseline = () => {
    const { data, error, loading } = useGetLiveBaselineQuery();

    if (!loading && error) {
        return (
            <ErrorCard>
                <H3>There is currently no live baseline available</H3>
            </ErrorCard>
        );
    }

    return (
        <Widget>
            <H3>Live Baseline</H3>
            <Hr />
            <Row>
                <Col col={12} sm={6} xl={8}>
                    <H5>{loading ? <Skeleton width="70%" /> : data?.description}</H5>
                    <P secondary lead>
                        {loading ? <Skeleton width="85%" /> : `Effective ${formatDateTime(data.effectiveDate)}`}
                    </P>
                    <LightText>
                        {loading ? <Skeleton /> : `Created by ${data.createdBy} on ${formatDateTime(data.createdAt)}`}
                    </LightText>
                </Col>
                {!!data?.id && (
                    <Col>
                        <LiveScheduledBaselineTestRunWidget id={data.id} />
                    </Col>
                )}
            </Row>
            <Hr />
            <Row>
                <Col col={12} sm={8} md={6} lg={4}>
                    {loading ? (
                        <Button secondary small disabled={true}>
                            View Baseline
                        </Button>
                    ) : (
                        <Link to={`/baselines/${data.id}`}>
                            <Button secondary small>
                                View Baseline
                            </Button>
                        </Link>
                    )}
                </Col>
            </Row>
        </Widget>
    );
};
