import { useDownloadTestResults } from './hooks';
import { Button } from '@reassure/ui/core';
import { DownloadIcon } from '@reassure/ui/icons/standard';
import { CondensedDownloadButton } from './styles';

interface DownloadBaselineTestResultsProps {
    resultReference: string;
}

export const DownloadBaselineTestResults = ({ resultReference }: DownloadBaselineTestResultsProps) => {
    const { downloadTestResults, loading, error } = useDownloadTestResults(resultReference);
    return (
        <Button small onClick={downloadTestResults} submitting={loading} disabled={error}>
            Download results
        </Button>
    );
};

export const CondensedDownloadBaselineTestResults = ({ resultReference }: DownloadBaselineTestResultsProps) => {
    const { downloadTestResults, loading, error } = useDownloadTestResults(resultReference);
    return (
        <CondensedDownloadButton small onClick={downloadTestResults} disabled={error || loading}>
            <DownloadIcon title="Download results" />
        </CondensedDownloadButton>
    );
};
