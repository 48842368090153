import { breakpointUp, Button } from '@reassure/ui/core';
import { InputGroup as RuiInputGroup } from '@reassure/ui/forms';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';

export const Link = styled(RouterLink)`
    color: ${({ theme }) => theme.colors.primaryText};
`;

export const FilterWrapper = styled.div`
    display: flex;
    justify-content: end;
`;

export const InputGroup = styled(RuiInputGroup)`
    select {
        width: auto;
        max-height: 35px;
        height: 35px;
        font-size: ${({ theme }) => theme.fontSizes[3]};
    }
    label {
        max-height: 35px;
        height: 35px;
        padding: 9px 7px 7px;
        font-size: ${({ theme }) => theme.fontSizes[3]};
        background-color: ${({ theme }) => theme.colors.background3};
        border-color: ${({ theme }) => theme.colors.midBlue};
    }
`;

export const BaselineListHeader = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    ${breakpointUp.sm`
        flex-direction: row;
    `}
`;

export const BaselineCreateWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${({ theme }) => theme.space[4]};

    h3 {
        margin-bottom: 0;
    }

    ${breakpointUp.sm`
        margin-bottom:0;
    `}
`;

export const CreateButton = styled(Button)`
    min-height: auto;
    margin-left: ${({ theme }) => theme.space[4]};
    padding: ${({ theme }) => theme.space[3]};
`;
