import { StyledSelect } from './styles';
import { UseFormRegister } from 'react-hook-form';

export interface SelectOptionType {
    label: string;
    value: string;
}
export interface SelectProps {
    id?: string;
    name: string;
    options: SelectOptionType[];
    register: UseFormRegister<any>;
    required: boolean;
    value?: string;
}

export const Select = ({ name, register, options, required, ...otherProps }: SelectProps) => (
    <StyledSelect name={name} input={{ ...register(name, { required }) }} options={options} {...otherProps} />
);
