import { gql } from '@apollo/client';
import { DocumentNode } from 'graphql';

export const OnCreateRateVersionSubscription: DocumentNode = gql`
    subscription OnCreateRateVersion {
        onCreateRateVersion {
            id
            createdAt
            createdBy
            description
            rateGroup
            version
        }
    }
`;
