import styled from 'styled-components';

const paddingLeft = '30px'

export const Li = styled.li`
    line-height: 200%;
`

export const Ol = styled.ol`
    padding-left: ${paddingLeft};
`

export const Ul = styled.ul`
    padding-left: ${paddingLeft};
    ${Li} {
        ::marker {
            font-size: 0.8rem;
        }
    }
`
