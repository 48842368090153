import { Col } from '@reassure/ui/core';
import { ArrowRightIcon } from '@reassure/ui/icons/standard';
import { BaselineRateVersion } from '../../graphql/types';
import { formatDateTime } from '../../utils/dates';
import { Badge, SuccessBadge } from '../Badges';
import { DownloadRateVersion } from './DownloadRateVersion';
import {
    ComparisonIconContainer,
    CreatedLabel,
    RateVersionOptionRow,
    Title,
    VersionBadgeContainer,
    VersionCol,
} from './styles';

export const RateVersion = ({
    comparisonVersion,
    createdAt,
    createdBy,
    description,
    rateGroup,
    version,
}: Partial<BaselineRateVersion>) => (
    <RateVersionOptionRow>
        <Col col={12} sm={9}>
            <Title>{description || 'No description provided'}</Title>
            <CreatedLabel small>
                Created by {createdBy} on {formatDateTime(createdAt!)}
            </CreatedLabel>
        </Col>
        <VersionCol col={12} sm={3}>
            {comparisonVersion && comparisonVersion !== version && (
                <>
                    <VersionBadgeContainer>
                        <SuccessBadge>Version {comparisonVersion}</SuccessBadge>
                        <DownloadRateVersion rateGroup={rateGroup} version={comparisonVersion} />
                    </VersionBadgeContainer>
                    <ComparisonIconContainer>
                        <ArrowRightIcon />
                    </ComparisonIconContainer>
                </>
            )}
            <VersionBadgeContainer>
                <Badge>Version {version}</Badge>
                <DownloadRateVersion rateGroup={rateGroup} version={version} />
            </VersionBadgeContainer>
        </VersionCol>
    </RateVersionOptionRow>
);
