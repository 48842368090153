import styled from 'styled-components';
import { Card as RuiCard } from '@reassure/ui/core';
import { H6 } from '../../Typography';

export const Card = styled(RuiCard)`
    ${H6} {
        margin-top: 0;
    }

    margin-bottom: ${({ theme }) => theme.space[5]};
`;
