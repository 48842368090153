import { useMutation, useQuery, useSubscription } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { DEPLOYMENT_FAILED, DRAFT, FAILED, REJECTED, TESTING_FAILED } from '../components/Baselines/BaselineStatus';
import { getStatusText } from '../components/Baselines/BaselineStatus/helpers';
import {
    CreateRateVersionDownloadUrlMutation,
    CreateRateVersionMutation,
    CreateRateVersionUploadUrlMutation,
} from './mutations/rateVersions';
import { ListRateGroups } from './queries/rateGroups';
import { ListRateVersionsByRateGroupQuery } from './queries/rateVersions';
import { OnCreateRateVersionSubscription } from './subscriptions/rateVersions';
import { Baseline, BaselineRateVersion, CreateSignedUrlResponse, RateGroup, RateVersion } from './types';
import {
    ApproveBaselineMutation,
    CreateBaselineMutation,
    CreateBaselineTestResultsDownloadUrlMutation,
    CreateBaselineTestRunMutation,
} from './mutations/baselines';
import {
    GetBaseline,
    GetBaselineRateVersions,
    GetLiveBaseline,
    ListBaselines,
    ListBaselineTestRuns,
} from './queries/baselines';
import {
    OnApproveBaselineSubscription,
    OnBaselineStatusUpdateSubscription,
    OnCompleteBaselineTestRunSubscription,
    OnCreateBaselineSubscription,
    OnStartBaselineTestRunSubscription,
} from './subscriptions/baselines';

export const sortByOrderAscending = ({ order: aOrder }: RateGroup, { order: bOrder }: RateGroup) => aOrder - bOrder;

export const useRateGroups = () => {
    const { data, error, loading } = useQuery(ListRateGroups);
    return {
        data:
            data?.listRateGroups?.items.length &&
            ([...data.listRateGroups.items].sort(sortByOrderAscending) as RateGroup[]),
        error,
        loading,
    };
};

export interface RateVersionsByGroupHookInput {
    limit: number;
    rateGroup: string;
}
export const useRateVersionsByRateGroup = ({ limit, rateGroup }: RateVersionsByGroupHookInput) => {
    const { data, error, loading, subscribeToMore } = useQuery(ListRateVersionsByRateGroupQuery, {
        variables: {
            limit,
            input: {
                rateGroup,
            },
        },
    });

    const subscribeToNewRateVersions = useCallback(
        () =>
            subscribeToMore({
                document: OnCreateRateVersionSubscription,
                updateQuery: (prev, { subscriptionData }) => {
                    const newRateVersion = subscriptionData?.data?.onCreateRateVersion;

                    if (!newRateVersion || newRateVersion.rateGroup !== rateGroup) return prev;

                    return {
                        ...prev,
                        listRateVersionsByRateGroup: {
                            items: [newRateVersion, ...prev.listRateVersionsByRateGroup.items],
                        },
                    };
                },
            }),
        [subscribeToMore, rateGroup]
    );

    useEffect(() => subscribeToNewRateVersions(), [subscribeToNewRateVersions]);

    return {
        data: data?.listRateVersionsByRateGroup?.items,
        error,
        loading,
    };
};

export const useCreateRateVersionMutation = () => {
    const [createRateVersionMeta, { data, loading, error }] = useMutation(CreateRateVersionMutation, {
        update(cache) {
            cache.evict({ fieldName: 'listRateVersionsByRateGroup' });
        },
    });
    return {
        createRateVersionMeta,
        data: data?.createRateVersion as RateVersion,
        error,
        loading,
    };
};

export const useCreateRateVersioDownloadUrlMutation = () => {
    const [createRateVersionDownloadUrl, { data, loading, error }] = useMutation(CreateRateVersionDownloadUrlMutation);
    return {
        createRateVersionDownloadUrl,
        data: data?.createRateVersionDownloadUrl as CreateSignedUrlResponse,
        error,
        loading,
    };
};

export const useCreateRateVersionUploadUrlMutation = () => {
    const [createRateVersionUploadUrl, { data, loading, error }] = useMutation(CreateRateVersionUploadUrlMutation);
    return {
        createRateVersionUploadUrl,
        data: data?.createRateVersionUploadUrl as CreateSignedUrlResponse,
        error,
        loading,
    };
};

export const useCreateBaselineMutation = () => {
    const [createBaseline, { data, loading, error }] = useMutation(CreateBaselineMutation, {
        update(cache) {
            cache.evict({ fieldName: 'listBaselines' });
        },
    });
    return {
        createBaseline,
        data: data?.createBaseline as Baseline,
        error,
        loading,
    };
};

export interface ListBaselinesHookInput {
    limit: number;
}

export const useListBaselinesQuery = ({ limit }: ListBaselinesHookInput) => {
    const { data, error, loading, subscribeToMore } = useQuery(ListBaselines, {
        variables: {
            limit,
        },
    });

    const subscribeToNewBaselines = useCallback(
        () =>
            subscribeToMore({
                document: OnCreateBaselineSubscription,
                updateQuery: (prev, { subscriptionData }) => {
                    if (!subscriptionData?.data?.onCreateBaseline) return prev;
                    const newBaseline = subscriptionData.data.onCreateBaseline;
                    newBaseline.status = DRAFT;

                    return Object.assign({}, prev, {
                        listBaselines: {
                            items: [newBaseline, ...prev.listBaselines.items],
                        },
                    });
                },
            }),
        [subscribeToMore]
    );

    useEffect(() => subscribeToNewBaselines(), [subscribeToNewBaselines]);

    return {
        data: data?.listBaselines?.items,
        error,
        loading,
    };
};

export interface IdInput {
    id: string;
}

export const useGetBaselineQuery = (id: string) => {
    const { data, error, loading } = useQuery(GetBaseline, {
        variables: {
            input: { id },
        },
    });

    return {
        data: data?.getBaseline as Baseline,
        error,
        loading,
    };
};

export const useGetLiveBaselineQuery = () => {
    const { data, error, loading, startPolling, stopPolling } = useQuery(GetLiveBaseline);

    useEffect(() => {
        startPolling(60000);
        return () => {
            stopPolling();
        };
    }, [startPolling, stopPolling]);

    return {
        data: data?.getLiveBaseline as Baseline,
        error,
        loading,
    };
};

export const useCreateBaselineTestRun = ({ id }: IdInput) => {
    const [createBaselineTestRun, { data, loading, error }] = useMutation(CreateBaselineTestRunMutation, {
        variables: {
            input: { id },
        },
    });

    useEffect(() => {
        if (!data && !error) return;
        if (error) {
            toast.error('Unable to start the The Baseline Test Run');
        }
        if (!!data?.createBaselineTestRun) {
            toast.success(`The Baseline Test Run has been successfully started`);
        }
    }, [data, error]);

    return {
        createBaselineTestRun,
        deployed: data?.createBaselineTestRun,
        error,
        submitting: loading,
    };
};

export const useOnBaselineStatusUpdate = () => {
    return useSubscription(OnBaselineStatusUpdateSubscription, {
        onData: ({
            data: {
                data: { onBaselineStatusUpdate },
            },
        }) => {
            if (!onBaselineStatusUpdate) return;
            const { description, status } = onBaselineStatusUpdate;
            const message = `The Baseline "${description}" has been updated to "${getStatusText(status)}"`;
            switch (status) {
                case DEPLOYMENT_FAILED:
                case FAILED:
                case REJECTED:
                case TESTING_FAILED:
                    toast.error(message);
                    break;
                default:
                    toast.success(message);
            }
        },
    });
};

export const useOnCreateBaseline = () => {
    return useSubscription(OnCreateBaselineSubscription);
};

export const useBaselineRateVersions = (id: string) => {
    const { data, error, loading } = useQuery(GetBaselineRateVersions, {
        variables: {
            input: { id },
        },
    });
    const { data: rateGroupData, error: rateGroupError, loading: rateGroupLoading } = useQuery(ListRateGroups);

    const [baselineRateVersions, setBaselineRateVersions] = useState([]);

    const mergeAndSortRateVersions = useCallback(() => {
        const mergedRateVersions = data.getBaselineRateVersions.map((rateVersion: BaselineRateVersion) => ({
            ...rateGroupData.listRateGroups.items.find(({ id }: IdInput) => id === rateVersion.rateGroup),
            ...rateVersion,
        }));

        mergedRateVersions.sort(sortByOrderAscending);
        setBaselineRateVersions(mergedRateVersions);
    }, [data, rateGroupData, setBaselineRateVersions]);

    useEffect(() => {
        if (data?.getBaselineRateVersions?.length && rateGroupData?.listRateGroups?.items.length) {
            mergeAndSortRateVersions();
        }
    }, [data, rateGroupData, mergeAndSortRateVersions]);

    return {
        data: baselineRateVersions as BaselineRateVersion[],
        error: error || rateGroupError,
        loading: loading || rateGroupLoading,
    };
};

export const useListBaselineTestRuns = (id: string) => {
    const { data, error, loading, subscribeToMore } = useQuery(ListBaselineTestRuns, {
        variables: {
            input: { id },
        },
    });

    const subscribeToNewTestRuns = useCallback(
        () =>
            subscribeToMore({
                document: OnStartBaselineTestRunSubscription,
                updateQuery: (prev, { subscriptionData }) => {
                    if (subscriptionData?.data?.onStartBaselineTestRun?.baselineId !== id) return prev;
                    const newTestRun = subscriptionData.data.onStartBaselineTestRun;

                    return Object.assign({}, prev, {
                        listBaselineTestRuns: {
                            items: [newTestRun, ...prev.listBaselineTestRuns.items],
                        },
                    });
                },
            }),
        [subscribeToMore, id]
    );

    const subscribeToCompletedTestRuns = useCallback(
        () =>
            subscribeToMore({
                document: OnCompleteBaselineTestRunSubscription,
                updateQuery: (prev, { subscriptionData }) => {
                    if (subscriptionData?.data?.onCompleteBaselineTestRun?.baselineId !== id) return prev;
                    const newTestRun = subscriptionData.data.onCompleteBaselineTestRun;

                    return Object.assign({}, prev, {
                        listBaselineTestRuns: {
                            items: [newTestRun, ...prev.listBaselineTestRuns.items],
                        },
                    });
                },
            }),
        [subscribeToMore, id]
    );

    useEffect(() => {
        subscribeToNewTestRuns();
        subscribeToCompletedTestRuns();
    }, [subscribeToNewTestRuns, subscribeToCompletedTestRuns]);

    return {
        data: data?.listBaselineTestRuns?.items,
        error,
        loading,
    };
};

export const usePreApprovalBaselineTestRun = (id: string) => {
    const { data, loading, error } = useListBaselineTestRuns(id);
    const testRuns = data?.filter((item: any) => item.startedBy !== 'Scheduled');
    const testRun = testRuns?.length > 0 ? testRuns[0] : null;

    return { testRun, loading, error };
};

export const useLatestScheduledBaselineTestRun = (id: string) => {
    const { data, loading, error } = useListBaselineTestRuns(id);
    const testRuns = data?.filter((item: any) => item.startedBy === 'Scheduled');
    const testRun = testRuns?.length > 0 ? testRuns[0] : null;

    return { testRun, loading, error };
};

export const useOnStartBaselineTestRun = () => {
    return useSubscription(OnStartBaselineTestRunSubscription);
};

export const useOnCompleteBaselineTestRun = () => {
    return useSubscription(OnCompleteBaselineTestRunSubscription);
};

export const useCreateBaselineTestResultsDownloadUrlMutation = () => {
    const [createBaselineTestResultsDownloadUrl, { data, loading, error }] = useMutation(
        CreateBaselineTestResultsDownloadUrlMutation
    );
    return {
        createBaselineTestResultsDownloadUrl,
        data: data?.createBaselineTestResultsDownloadUrl as CreateSignedUrlResponse,
        error,
        loading,
    };
};

export const useApproveBaseline = (id: string) => {
    const [approveBaseline, { data, loading, error }] = useMutation(ApproveBaselineMutation, {
        variables: {
            input: { id },
        },
        update(cache) {
            cache.evict({ fieldName: 'listBaselines' });
        },
    });
    return {
        approveBaseline,
        data: data?.approveBaseline as Baseline,
        error,
        loading,
    };
};

export const useOnApproveBaseline = () => {
    return useSubscription(OnApproveBaselineSubscription);
};

export const useOnCreateRateVersion = () => {
    return useSubscription(OnCreateRateVersionSubscription);
};
