import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import utc from 'dayjs/plugin/utc';
import { BaselineRateVersionInput, CreateBaselineFormValues } from '../../../graphql/types';

dayjs.extend(customParseFormat);
dayjs.extend(utc);

export const transformCreateBaselineFormValues = ({
    description,
    rates: formRates,
    effectiveDate: formDateTime,
}: CreateBaselineFormValues) => {
    const rateGroups = Object.keys(formRates);
    const rates: BaselineRateVersionInput[] = [];
    for (const rateGroup of rateGroups) {
        rates.push({
            rateGroup,
            version: parseInt(formRates[rateGroup]),
        });
    }
    const effectiveDate = dayjs(formDateTime, 'DD/MM/YYYY HH:mm').utc().format();

    return {
        description,
        rates,
        effectiveDate,
    };
};
