import styled from 'styled-components';
import { Card as RuiCard } from '@reassure/ui/core';
import { H1, H2, H3, H4 } from '../Typography';
import { fadeScaleLinear } from '../Animations';

export const Widget = styled(RuiCard)`
    border: 1px solid ${({ theme }) => theme.colors.background2};
    ${H1}, ${H2}, ${H3}, ${H4} {
        margin-top: 0;
    }
    margin-bottom: ${({ theme }) => theme.space[6]};

    animation-name: ${fadeScaleLinear(0.98, 1)};
    animation-duration: 0.25s;
    animation-iteration-count: 1;
`;
