import { DocumentNode, gql } from '@apollo/client';

export const OnApproveBaselineSubscription: DocumentNode = gql`
    subscription OnApproveBaseline {
        onApproveBaseline {
            id
            createdAt
            createdBy
            description
            effectiveDate
            status
            events {
                status
                updatedAt
                updatedBy
            }
        }
    }
`;

export const OnBaselineStatusUpdateSubscription: DocumentNode = gql`
    subscription OnBaselineStatusUpdate {
        onBaselineStatusUpdate {
            id
            description
            status
            events {
                status
                updatedAt
                updatedBy
            }
        }
    }
`;

export const OnCreateBaselineSubscription: DocumentNode = gql`
    subscription OnCreateBaseline {
        onCreateBaseline {
            id
            createdAt
            createdBy
            description
            effectiveDate
            events {
                status
                updatedAt
                updatedBy
            }
        }
    }
`;

export const OnStartBaselineTestRunSubscription: DocumentNode = gql`
    subscription OnStartBaselineTestRun {
        onStartBaselineTestRun {
            id
            baselineId
            startedAt
            startedBy
            completedAt
            testPackId
            totalTestScenarios
            resultReference
            error
        }
    }
`;

export const OnCompleteBaselineTestRunSubscription: DocumentNode = gql`
    subscription OnCompleteBaselineTestRun {
        onCompleteBaselineTestRun {
            id
            baselineId
            startedAt
            startedBy
            completedAt
            testPackId
            totalTestScenarios
            resultReference
            error
        }
    }
`;
