import { useCallback, useEffect } from 'react';
import { Skeleton, Col } from '@reassure/ui/core';
import { useFormContext } from 'react-hook-form';
import { useRateVersionsByRateGroup } from '../../../graphql/hooks';
import { RateGroup, RateVersion as RateVersionType } from '../../../graphql/types';
import { ErrorCard } from '../../ErrorCard';
import { CardRadioGroup } from '../../Forms/CardRadioGroup';
import { H6, P } from '../../Typography';
import { FormSectionCard } from '../CreateBaselineForm/styles';
import { RateVersion } from '../../RateVersions';
import { NewRateGroupRateForm } from '../../NewRateForm';
import { RateGroupRow } from './styles';
import { useLiveBaselineRateVersion } from '../CreateBaselineForm/hooks';

export const RateVersionRadios = ({ id, name, ...otherProps }: RateGroup) => {
    const { register, setValue, getValues } = useFormContext();
    const { data, error, loading } = useRateVersionsByRateGroup({ limit: 5, rateGroup: id });
    const { rateVersion } = useLiveBaselineRateVersion(id);

    const setRateVersionValue = useCallback(
        (rateVersion: string) => {
            setValue(`rates.${id}`, rateVersion);
        },
        [setValue, id]
    );

    useEffect(() => {
        const currentValue = getValues(`rates.${id}`);

        if (currentValue && currentValue !== rateVersion) {
            setRateVersionValue(currentValue);
        } else {
            setRateVersionValue(rateVersion);
        }
    }, [rateVersion, data, getValues, id, setRateVersionValue]);

    if (error) return <ErrorCard>There was an error retrieving the rate version for rate group "{name}"</ErrorCard>;

    if (loading)
        return (
            <P>
                <Skeleton count={5} />
            </P>
        );

    return (
        <>
            <CardRadioGroup
                id={`rates-${id}`}
                name={`rates.${id}`}
                radios={data.map((rateVersion: RateVersionType) => ({
                    id: `rates-${id}-${rateVersion.id}`,
                    label: <RateVersion {...rateVersion} />,
                    value: rateVersion.version,
                }))}
                register={register}
                required={true}
                {...otherProps}
            />
            {!data?.length && <ErrorCard>No rate versions are available</ErrorCard>}
        </>
    );
};

export interface RateVersionSelectorProps {
    loading: Boolean;
    rateGroup?: RateGroup;
}

const defaultRateVersionSelectorProps = {
    loading: false,
};

export const RateVersionSelector = ({
    loading,
    rateGroup,
    ...otherProps
}: RateVersionSelectorProps & typeof defaultRateVersionSelectorProps) => {
    return (
        <FormSectionCard {...otherProps}>
            {loading ? (
                <Skeleton />
            ) : (
                <RateGroupRow>
                    <Col col={12} md={7} lg={8} xl={9}>
                        <H6>{rateGroup?.name}</H6>
                    </Col>
                    <Col col={12} md={5} lg={4} xl={3}>
                        <NewRateGroupRateForm rateGroup={rateGroup!} />
                    </Col>
                </RateGroupRow>
            )}

            {loading ? (
                <P>
                    <Skeleton count={5} />
                </P>
            ) : (
                <RateVersionRadios {...rateGroup!} />
            )}
        </FormSectionCard>
    );
};

RateVersionSelector.defaultProps = defaultRateVersionSelectorProps;
