import { breakpointUp, Button, Col as RuiCol, P as RuiP, Row as RuiRow } from '@reassure/ui/core';
import styled from 'styled-components';
import { P } from '../Typography';

export const Title = styled(P)`
    margin: 0 0 ${({ theme }) => theme.space[4]};
`;

Title.defaultProps = {
    secondary: true,
};

export const CreatedLabel = styled(RuiP)`
    margin: 0;

    font-size: ${({ theme }) => theme.fontSizes[2]};
    ${breakpointUp.sm`
        margin: 0;
        // text-align: right;
    `}
`;

export const VersionCol = styled(RuiCol)`
    display: flex;
    align-items: center;
    ${breakpointUp.sm`
        flex-wrap: wrap;
        justify-content: center;
    `}
`;

export const RateVersionOptionRow = styled(RuiRow)`
    width: 100%;
`;

export const ComparisonIconContainer = styled.div`
    margin: ${({ theme }) => theme.space[4]};
    margin-bottom: 0;

    ${breakpointUp.sm`
        width: 100%;
        margin-bottom: 5px;
        text-align: center;

        svg{
            transform: rotate(90deg);
        }
    `}
`;

export const VersionBadgeContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    ${breakpointUp.sm`
        flex-wrap: wrap;
        justify-content: center;
    `}
`;

export const DownloadButton = styled(Button)`
    width: auto;
    min-height: 0;
    line-height: 1;
    margin-top: 8px;
    margin-left: 4px;
    padding: 0;
    border: none;
    &:active,
    &:hover,
    &:visited {
        border: none;
    }
    svg {
        height: 20px;
        width: 20px;
    }

    ${breakpointUp.sm`
        position: absolute;
        right: -28px;
        margin-top: 0;
        margin-left: 0;
    `}
`;
